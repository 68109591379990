import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage('success');
const errorMessage = new NotiMessage('error');

//URL List
const eachDataUrl = "patient/pre_enroll/support/getEach?";
const allDataUrl = "patient/pre_enroll/support/get?";
const storeUrl = "patient/pre_enroll/support/store";
const deleteUrl = "patient/pre_enroll/support/delete";

const getDefaultState = () => {
  return {
    enrollEach: {},
  };
};

const state = {
  preEnrollData: [],
  preEnrollEach: {},
};
const getters = {};
const mutations = {
  SET_PRE_ENROLL_DATA(state, payload) {
    state.preEnrollData = payload.data.data;
  },
  SET_PRE_ENROLL_EACH(state, payload) {
    state.preEnrollEach = payload.data.data;
  },
  ADD_PRE_ENROLL(state, payload) {
    state.preEnrollData.push(payload.data.data);
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  REMOVE_PRE_ENROLL_DATA(state, payload) {
    state.preEnrollData = state.preEnrollData.filter((val) => {
      return val.id != payload;
    });
  },
};

const actions = {
  async getPreEnrollEach({ commit }, id) {
    commit("resetState");
    const response = await Request.get_data(eachDataUrl, { column: 'id', value: id })
    const responseChecker = new ResponseChecker(response, 'Saved Success')
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_PRE_ENROLL_EACH", response);
      return response;
    }
  },
  async getPreEnroll({ commit }, id) {
    const response = await Request.get_data(allDataUrl, { patient_id: id })
    const responseChecker = new ResponseChecker(response, 'Saved Success')
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_PRE_ENROLL_DATA", response);
      return response;
    }
  },
  async createPreEnroll(context, payload) {
    const response = await Request.post_data(storeUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      location.reload();
      notiMessage.displayNotiMsg('Saved Success')
      return true
    }
    errorMessage.displayNotiMsg(response.data.message)
    return false;
  },
  async delete({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_PRE_ENROLL_DATA", payload.id);
      notiMessage.displayNotiMsg('Deleted Success')
      return true
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
