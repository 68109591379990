import Request from "../../services/Request";
import ResponseChecker from '../../services/ResponseChecker';
import router from "@/router";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage('success');

//clear all state
const getDefaultState = () => {
  return {
    dotsPatients: [],
  };
};

//URL List
const eachDataUrl = "/dots_patient/volunteer_activity/getEach";
const allDataUrl = "/dots_patient/volunteer_activity/get?";
const storeUrl = "/dots_patient/volunteer_activity/store";
const updateUrl = "/dots_patient/volunteer_activity/update";
const dotsPatientUrl = "/report/volunteer/dots_patient?";
const dotsFinishedPatientUrl = '/report/volunteer/finished_dots_patient?'

const state = {
  datas: [],
  data: {},
  dotsPatients: []
};
const getters = {};
const mutations = {
  SET_DATA(state, payload) {
    state.datas = payload;
  },
  SET_DOTS(state, payload) {
    state.dotsPatients = payload;
  },
  APPEND_DATA(state, payload) {
    state.datas.push(payload);
  },
  REMOVE_DATA(state, payload) {
    state.datas = state.datas.filter((val) => {
      return val.id != payload;
    });
  },
  SET_EACH_DATA(state, payload) {
    state.data = payload;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async getAll({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload)
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_DATA", response.data.data);
      return true
    }
    return false
  },
  async getDotsPatients({ commit }, payload) {
    commit("resetState");
    const response = await Request.get_data(dotsPatientUrl, payload)
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_DOTS", response.data.data);
      return true
    }
    return false
  },
  async getDotsFinishPatients({ commit }, payload) {
    commit("resetState");
    const response = await Request.get_data(dotsFinishedPatientUrl, payload)
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_DOTS", response.data.data);
      return true
    }
    return false
  },
  async getEach({ commit }, payload) {
    const response = await Request.get_data(eachDataUrl, payload)
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_EACH_DATA", response.data.data)
      return true
    }
    return false
  },
  async save(context, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      router.push(`/dots-detail/${payload.dots_patient_id}`);
      notiMessage.displayNotiMsg('Saved Success')
      return true
    }
    return false
  },
  async update(context, payload) {
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      router.push(`/dots-detail/${payload.dots_patient_id}`);
      notiMessage.displayNotiMsg('Updated Success')
      return true
    }
    return false
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
