import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";
import { getCustomFields } from "../../services/ExportedPatientCustomField";

const notiMessage = new NotiMessage("success");

//URL List
const eachDataUrl = "patient/getEach?";
const allDataUrl = "patient/get?";
const exportedDataUrl = "patient/export?";
const storeUrl = "patient/store";
const updateUrl = "patient/update";
const deleteUrl = "patient/delete";

const getDefaultState = () => {
  return {
    patient: {},
    isPreEnroll: false,
  };
};

const state = {
  patients: [],
  townshipPatients:[],
  exported_patients: [],
  patient: {},
  isPreEnroll: false,
  pageCount: 1,
};
const getters = {
  dotsPatientHandler(state) {
    var patient = state.patient;
    return {
      id: patient.id ? patient.id : "",
      name: patient.name ? patient.name : "",
      dob: patient.dob ? patient.dob : "",
      age: patient.age ? patient.age : "",
      address: patient.address ? patient.address : "",
      phone: "",
      sex: patient.sex ? patient.sex : "",
      volunteer: "",
      weight: "",
      tb_code: patient.mdrtb_reg_no ? patient.mdrtb_reg_no : "",
      tb_year: patient.mdrtb_reg_year ? patient.mdrtb_reg_year : "",
      type_of_tb: patient.type_of_tb ? patient.type_of_tb : "",
      mdrtb_regiment: patient.mdrtb_regiments ? patient.mdrtb_regiments[0] : "",
      treatment_startdate: patient.treatment_startdate
        ? patient.treatment_startdate
        : "",
      dots_startdate: "",
    };
  },
  exportedPatientHandler(state) {
    let maxEnrollCount = 0;
    if (state.exported_patients.length > 0) {
      state.exported_patients.map((val) => {
        if (val.enroll_supports.length > maxEnrollCount)
        maxEnrollCount = val.enroll_supports.length;
      });

     return state.exported_patients.map((val) => {
        getCustomFields(val, maxEnrollCount)
      });
    }
    return []
  },
};
const mutations = {
  SET_PRE_ENROLL_YES(state) {
    state.isPreEnroll = true;
  },
  SET_PATIENTS(state, payload) {
    state.patients = payload;
  },
  SET_EXPORTED_PATIENTS(state, payload) {
    state.exported_patients = payload.data.data;
  },
  SET_PATIENT(state, payload) {
    state.patient = payload.data.data;
  },
  ADD_PATIENT(state, payload) {
    state.patients.push(payload.data.data);
  },
  REMOVE_PATIENT(state, payload) {
    const patients = state.patients.filter((item) => item.id != payload.id);
    state.patients = [...patients];
  },
  Set_Township_Patients(state,payload){
    state.townshipPatients = payload;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_PATIENT_PAGE(state, payload) {
    state.pageCount = payload;
  },
};

const actions = {
  async getPatients({ commit }, params) {
    const response = await Request.get_data(allDataUrl, params);
    const responseChecker = new ResponseChecker(response);
    console.log(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_PATIENTS", response.data.data.result);
      commit("SET_PATIENT_PAGE", response.data.data.page);
      return true;
    }
    return false;
  },
  async getPatientsByTownship({ commit }, params) {
    const response = await Request.get_data('patient/get_patients_by_township?', params);

    if (response.status) {
      commit("Set_Township_Patients", response.data.data);
      return true;
    }
    return false;
  },
  async getReportedPatient(commit, params) {
    const response = await Request.get_data(exportedDataUrl, params);
      // commit("SET_EXPORTED_PATIENTS", response);
      let maxEnrollCount = 0;
      if (response.data.data.length > 0) {
       response.data.data.map((val) => {
          if (val.enroll_supports.length > maxEnrollCount)
          maxEnrollCount = val.enroll_supports.length;
        });
        console.log(response.data.data.map((v) => {
          // console.log(getCustomFields(v, maxEnrollCount));
         return getCustomFields(v, maxEnrollCount)
        }));
       return response.data.data.map((val) => getCustomFields(val, maxEnrollCount));
      }
    return false;
  },
  async getPatientsByVolunteer({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_PATIENTS", response);
  },
  async searchPatientsByName({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_PATIENTS", response);
  },
  async searchPatientsByCode({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_PATIENTS", response);
  },
  async searchPatientBySupport({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_PATIENTS", response);
  },
  async searchPatientByOnging({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_PATIENTS", response);
  },
  async searchPatientsByNominated({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_PATIENTS", response);
  },
  async getPatient({ commit }, id) {
    commit("resetState");
    const response = await Request.get_data(eachDataUrl, {
      column: "id",
      value: id,
    });
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_PATIENT", response);
      if (response.data.data.pre_enroll == 1)
        commit("SET_PRE_ENROLL_YES", response);
      return true;
    }
    return false;
  },
  async createPatient({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("ADD_PATIENT", response);
      notiMessage.displayNotiMsg("Saved Success");
      return true;
    }
    return false;
  },
  async updatePatient(context, payload) {
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg("Updated Success");
      return true;
    }
    return false;
  },
  async deletePatient({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_PATIENT", payload);
      notiMessage.displayNotiMsg("Deleted Success");
      return true;
    }
    return false;
  },

  async exportPatient({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_PATIENT", payload);
      notiMessage.displayNotiMsg("Deleted Success");
      return true;
    }
    return false;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
