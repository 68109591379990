import Request from "../../services/Request";
import router from "@/router";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage("success");

//URL List
const eachDataUrl = "dots_patient/getEach?";
const allDataUrl = "dots_patient/get?";
const storeUrl = "dots_patient/store";
const updateUrl = "dots_patient/update";
const finishDoturl = "dots_patient/visit_date/finish";
const visitDateurl = "dots_patient/visit_date/get?";

const state = {
  datas: [],
  dots_patients:[],
  data: {},
  pageCount: 1,
};
const getters = {};
const mutations = {

  SET_DATA(state, payload) {
    state.datas = payload;
  },
  APPEND_DATA(state, payload) {
    state.datas.push(payload);
  },
  REMOVE_DATA(state, payload) {
    state.datas = state.datas.filter((val) => {
      return val.id != payload;
    });
  },
  SET_EACH_DATA(state, payload) {
    state.data = payload;
  },
  SET_PATIENT_PAGE(state, payload) {
    state.pageCount = payload;
  },
};

const actions = {
  async getAll({ commit },params) {
    const response = await Request.get_data(allDataUrl,params);
    if (response.data.success) {
      commit("SET_DATA", response.data.data.result);
      commit("SET_PATIENT_PAGE",response.data.data.page);
      
      return true;
    }
    return false;
  },
  async getByFilter({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    if (response.data.success) {
      commit("SET_DATA", response.data.data.result);
      commit("SET_PATIENT_PAGE",response.data.data.page);
      return true;
    }
    return false;
  },

  async getEach({ commit }, payload) {
    const response = await Request.get_data(eachDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    console.log(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_EACH_DATA", response.data.data);
      return true;
    }
    return false;
  },
  async save(_, payload) {
    const response = await Request.post_data(storeUrl, payload);
    if (response.status) {
      notiMessage.displayNotiMsg("Saved Success");
      return true;
    }
    return false;
  },
  async update(context, payload) {
    console.log(payload);
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg("Updated Success");
      return true;
    }
    return false;
  },
  async finishDOTS({ commit }, payload) {
    console.log(commit);
    const response = await Request.post_data(finishDoturl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      router.push(`/dots-detail/${payload.dots_patient_id}`);
      notiMessage.displayNotiMsg("Finished DOTS");
      return true;
    }
    return false;
  },
  async getVisitDate({ commit }, payload) {
    console.log(commit);
    const response = await Request.get_data(visitDateurl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      return response.data.data;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
