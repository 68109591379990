<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-card-title
          class="d-flex justify-space-between headline purple accent-4 py-1"
        >
          <div class="subtitle-1 white--text">Change Password</div>
          <div>
            <v-btn @click="closeDialog" class="white--text" icon>
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-text-field
            placeholder="Old Password"
            prepend-inner-icon="mdi-lock"
            v-model.trim="oldPassword"
            :error-messages="handleFieldErrors($v.oldPassword)"
            type="password"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            placeholder="New Password"
            prepend-inner-icon="mdi-lock"
            v-model.trim="newPassword"
            :error-messages="handleFieldErrors($v.newPassword)"
            type="password"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            placeholder="Repeat New Password"
            prepend-inner-icon="mdi-lock"
            v-model.trim="retypePassword"
            :error-messages="handleFieldErrors($v.retypePassword)"
            type="password"
            outlined
            dense
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="px-5 py-2">
          <v-spacer></v-spacer>
          <v-btn @click="changePassword" class="form-btn"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { ValidationMixin } from "./../../mixins/ValidationMixin";
import { mapState } from "vuex";

export default {
  mixins: [ValidationMixin],
  data: () => ({
    oldPassword: "",
    newPassword: "",
    retypePassword: "",
  }),
  validations: {
    newPassword: {
      required,
      minLength: minLength(4),
    },
    oldPassword: {
      required,
      minLength: minLength(4),
    },
    retypePassword: {
      required,
      minLength: minLength(4),
      sameAs: sameAs("newPassword"),
    },
  },
  computed: {
    ...mapState("Loading", ["loading"]),
  },
  methods: {
    closeDialog() {
      this.$store.dispatch("Admin/setDialog");
    },
    changePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        };
        this.$store.dispatch("Admin/changePassword", data);

        this.$v.$reset();

        this.oldPassword = "";
        this.newPassword = "";
        this.retypePassword = "";

        this.closeDialog();
      }
    },
  },
  props: {
    dialog: {
      type: Boolean,
    },
  },
};
</script>

<style scoped></style>
