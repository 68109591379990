import Request from "../../services/Request";
import ResponseChecker from '../../services/ResponseChecker';
import NotiMessage from "../../services/NotiMessage";
import { getCustomFields } from '../../services/export-excel/ExportCounselingField';

const notiMessage = new NotiMessage('success');


//URL for Counseling
const eachDataUrl = "activity/counseling/getEach?";
const allDataUrl = "activity/counseling/get?";
const storeUrl = "activity/counseling/store";
const updateUrl = "activity/counseling/update";
const deleteUrl = "activity/counseling/delete";

const getDefaultState = () => {
  return {
    counselingEachData: {},
  };
};

const state = {
  counselingData: [],
  counselingEachData: {},
};
const getters = {
  exportFieldsHandler(state) {
    let counselingData = [];
    if (state.counselingData.length > 0) {
      state.counselingData.map((element) => {
        counselingData.push(getCustomFields(element))
      })
    }
    return counselingData;
  }
};
const mutations = {
  SET_COUNSELING_DATA(state, payload) {
    state.counselingData = payload;
  },
  SET_COUNSELING_EACH(state, payload) {
    state.counselingEachData = payload;
  },
  ADD_COUNSELING_DATA(state, payload) {
    state.counselingData.push(payload);
  },
  REMOVE_COUNSELING_DATA(state, payload) {
    const counselingData = state.counselingData.filter(
      (item) => item.id != payload.id
    );
    state.counselingData = [...counselingData];
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async getAll({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_COUNSELING_DATA", response.data.data);
  },
  async getUncompletedList({ commit }, payload) {
    const response = await Request.get_data(
      "activity/counseling/getUncompletedList?",
      { ids: JSON.stringify(payload.ids) }
    );
    commit("SET_COUNSELING_DATA", response.data.data);
  },
  async getEach({ commit }, id) {
    commit("resetState");
    const response = await Request.get_data(eachDataUrl, { column: 'id', value: id });
    console.log(response);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_COUNSELING_EACH", response.data.data)
      return true
    }
    return false;
  },
  async create({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("ADD_COUNSELING_DATA", response.data.data);
      notiMessage.displayNotiMsg('Saved Success')
      return true
    }
    return false;
  },
  async update(context, payload) {
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg('Updated Success')
      return true
    }
    return false
  },
  async delete({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_COUNSELING_DATA", payload);
      notiMessage.displayNotiMsg('Deleted Success')
      return true
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
