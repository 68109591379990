import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage('success');

//URL List
const allDataUrl = "volunteer/trainings/get?";
const storeUrl = "volunteer/trainings/store";
const deleteUrl = "volunteer/trainings/delete";


const state = {
  trainingData: [],
};
const getters = {};
const mutations = {
  SET_TRAINING_DATA(state, payload) {
    state.trainingData = payload.data.data;
  },
  ADD_TRAINING_DATA(state, payload) {
    state.trainingData.push(payload.data.data);
  },
  REMOVE_TRAINING_DATA(state, payload) {
    const trainingData = state.trainingData.filter(
      (item) => item.id != payload.id
    );
    state.trainingData = [...trainingData];
  },
};

const actions = {
  async getVolTraningData({ commit }, volunteerId) {
    const response = await Request.get_data(allDataUrl, { volunteer_id: volunteerId })
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_TRAINING_DATA", response);
  },
  async createVolTraining({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("ADD_TRAINING_DATA", response);
      notiMessage.displayNotiMsg('Saved Success')
      return true
    }
    return false;
  },
  async deleteVolTraining({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_TRAINING_DATA", payload);
      notiMessage.displayNotiMsg('Deleted Success')
      return true
    }

    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
