import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage('success');

//URL List
const eachDataUrl = "/dots_patient/visit_report/getEach?";
const allDataUrl = "/dots_patient/visit_report/get?";
const storeUrl = "/dots_patient/visit_report/store";
const updateUrl = "/dots_patient/visit_report/update";
const deleteUrl = "/dots_patient/visit_report/delete";

const state = {
  datas: [],
  data: {},
};
const getters = {};
const mutations = {
  SET_DATA(state, payload) {
    state.datas = payload.data.data;
  },
  ADD_DATA(state, payload) {
    state.datas.push(payload.data.data);
  },
  REMOVE_DATA(state, payload) {
    const datas = state.datas.filter(
      (item) => item.id != payload.id
    );
    state.datas = [...datas];
  },
  SET_EACH_DATA(state, payload) {
    state.data = payload.data.data;
  },
};

const actions = {
  async getAll({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) commit("SET_DATA", response);
  },
  async getEach({ commit }, payload) {
    const response = await Request.get_data(eachDataUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) commit("SET_EACH_DATA", response);
  },
  async save({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit('ADD_DATA', response)
      notiMessage.displayNotiMsg('Saved Success')
      return true
    }
    return false
  },
  async update(context, payload) {
    const response = await Request.post_data(updateUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg('Updated Success')
      return true
    }
    return false
  },
  async delete({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_DATA", payload);
      notiMessage.displayNotiMsg('Deleted Success')
      return true
    }
    return false
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
