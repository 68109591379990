import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";
import apiClient from "../../services/Http";

const notiMessage = new NotiMessage("success");

//URL List
const eachDataUrl = "presumptive/getEach?";
const allDataUrl = "presumptive/get?";
const indexCodeUrl = "presumptive/index_code?";
const storeUrl = "presumptive/store";
const addTimesUrl = "presumptive/diagnosis/store";
// const exportUrl = "presumptive/export?"

const state = {
    presumptiveData: [],
    presumptiveDataEach: {},
};
const getters = {};
const mutations = {
    SET_PRESUMPTIVE_DATA(state, payload) {
        state.presumptiveData = payload.data.data;
    },
    SET_PRESUMPTIVE_DATA_EACH(state, payload) {
        state.presumptiveDataEach = payload.data.data;
    },
};

const actions = {
    async getPresumptiveEach({ commit }, id) {
        const response = await Request.get_data(eachDataUrl, {
            column: "id",
            value: id,
        });
        const responseChecker = new ResponseChecker(response, "Saved Success");
        responseChecker.checkResponse();
        if (responseChecker.isResponseStatusSuccess())
            commit("SET_PRESUMPTIVE_DATA_EACH", response);
    },
    async getPresumptiveData({ commit }, payload) {
        const response = await Request.get_data(allDataUrl, payload);
        const responseChecker = new ResponseChecker(response, "Saved Success");
        responseChecker.checkResponse();
        if (responseChecker.isResponseStatusSuccess())
            commit("SET_PRESUMPTIVE_DATA", response);
    },
    async searchByIndexCode({ commit }, payload) {
        const response = await Request.get_data(indexCodeUrl, payload);
        const responseChecker = new ResponseChecker(response, "Saved Success");
        responseChecker.checkResponse();
        if (responseChecker.isResponseStatusSuccess())
            commit("SET_PRESUMPTIVE_DATA", response);
    },
    async createPresumptive(context, payload) {
        const response = await Request.post_data(storeUrl, payload);
        const responseChecker = new ResponseChecker(response);
        responseChecker.checkResponse();
        if (responseChecker.isResponseStatusSuccess()) {
            notiMessage.displayNotiMsg("Saved Success");
            return true;
        }
        return false;
    },
    async dotsgetExport(context, payload) {
        let url = `https://api.mdr.tbtheunion.com/api/pa/dots/patient/export?`;
        if (payload) {
            Object.keys(payload).forEach((key) => {
                url += `&${key}=${payload[key]}`;
            });
        }
        await apiClient
            .get(
                url, {
                    responseType: "blob",
                }
            )
            .then(() => {
                window.location = url;
            });
    },
   
    //   async updatePatient({ dispatch }, payload) {
    //     const response = await Patient.updatePatient(payload).then((response) => {
    //       console.log(response);
    //       if (response.data.success) {
    //         const notification = {
    //           type: "success",
    //           status: true,
    //           message: "update patient Success!",
    //         };

    //         dispatch("Notification/add", notification, { root: true });
    //       }
    //     });

    //     return response;
    //   },
    //   async deletePatient({ commit, dispatch }, payload) {
    //     const response = await Patient.deletePatient(payload).then((response) => {
    //       console.log(response);
    //       if (response.data.success) {
    //         const notification = {
    //           type: "success",
    //           status: true,
    //           message: "delete patient successfully!",
    //         };
    async addTimes(context, payload) {
        const response = await Request.post_data(addTimesUrl, payload);
        const responseChecker = new ResponseChecker(response);
        responseChecker.checkResponse();
        if (responseChecker.isResponseStatusSuccess()) {
            notiMessage.displayNotiMsg("Added Diagnosis Times Success");
            return true;
        }
        return false;
    },
    async getExport(context, payload) {
        let url = `https://api.mdr.tbtheunion.com/api/presumptive/export?`;
        if (payload) {
            Object.keys(payload).forEach((key) => {
                url += `&${key}=${payload[key]}`;
            });
        }
        await apiClient
            .get(
                url, {
                    responseType: "blob",
                }
            )
            .then(() => {
                window.location = url;
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};