import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";

//URL List
const form7Url = "report/form7/get?";
const monthlyUrl = "report/monthly/get?";
const volunteerReportedUrl = "/report/volunteer/reported?";
const volunteerDOTSReportedUrl = "/report/volunteer/dots/reported?";
const volunteerDetailUrl = "/report/volunteer/detail?";
const nominationList = "/report/nomination/get?";
const nominationList14A = "/report/nomination14A/get?";

const getDefaultState = () => {
  return {
    datas: [],
    volDetail: [],
    volData: [],
    nominationList: [],
  };
};

const state = {
  datas: [],
  volDetail: [],
  volReportedData: [],
  volDotsReportedData: [],
  nominationList: [],
};
const getters = {};
const mutations = {
  SET_DATAS(state, payload) {
    state.datas = payload;
  },
  SET_VOL_DETAIL(state, payload) {
    state.volDetail = payload;
  },
  SET_VOL_D_R_DATA(state, payload) {
    state.volDotsReportedData = payload;
  },
  SET_VOL_R_DATA(state, payload) {
    state.volReportedData = payload;
  },
  SET_NOM_LIST(state, payload) {
    state.nominationList = payload;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async getForm7({ commit }, payload) {
    commit("resetState");
    const response = await Request.get_data(form7Url, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_DATAS", response.data.data);
      return response;
    }
  },
  async getMonthly({ commit }, payload) {
    commit("resetState");
    const response = await Request.get_data(monthlyUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_DATAS", response.data.data);
      return response;
    }
  },
  async getVolunteerReported({ commit }, params) {
    const response = await Request.get_data(volunteerReportedUrl, params);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_VOL_R_DATA", response.data.data);
      return response;
    }
  },
  async getNominationList({ commit }, params) {
    const response = await Request.get_data(nominationList, params);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_NOM_LIST", response.data.data);
      return response;
    }
  },
  async getNominationList14A({ commit }, params) {
    const response = await Request.get_data(nominationList14A, params);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_NOM_LIST", response.data.data);
      return response;
    }
  },
  async getVolunteerDOTSReported({ commit }, params) {
    const response = await Request.get_data(volunteerDOTSReportedUrl, params);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_VOL_D_R_DATA", response.data.data);
      return response;
    }
  },
  async getVolDetail({ commit }, params) {
    const response = await Request.get_data(volunteerDetailUrl, params);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_VOL_DETAIL", response.data.data);
      return response;
    }
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
