import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage("success");

//URL for List
const eachDataUrl = "delagated_person/getEach?";
const allDataUrl = "delagated_person/get?";
const storeUrl = "delagated_person/store";
const updateUrl = "delagated_person/update";
const deleteUrl = "delagated_person/delete";

const state = {
  delagatedPersons: [],
  delagatedPerson: {},
};
const getters = {};
const mutations = {
  SET_DELAGATED_PERSON(state, payload) {
    state.delagatedPersons = payload;
  },
  ADD_DELAGATED_PERSON(state, payload) {
    state.delagatedPersons.push(payload);
  },
  REMOVE_DELAGATED_PERSON(state, payload) {
    state.delagatedPersons = state.delagatedPersons.filter((val) => {
      return val.id != payload;
    });
  },
  SET_EACH_DELAGATED_PERSON(state, payload) {
    state.delagatedPerson = payload;
  },
};

const actions = {
  async getAll({ commit }, id) {
    const response = await Request.get_data(allDataUrl, { patient_id: id });

    commit("SET_DELAGATED_PERSON", response.data.data);
  },
  async getEach({ commit }, id) {
    const response = await Request.get_data(eachDataUrl, {
      column: "id",
      value: id,
    });

    commit("SET_EACH_DELAGATED_PERSON", response.data.data);
  },
  async create({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload);

    commit("ADD_DELAGATED_PERSON", response.data.data);
    notiMessage.displayNotiMsg("Saved Success");
    return true;
  },

  async update(context, payload) {
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg("Updated Success");
      return true;
    }
    return false;
  },

  async delete({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_DELAGATED_PERSON", payload.id);
      notiMessage.displayNotiMsg("Deleted Success");
      return true;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
