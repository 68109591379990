import ResponseChecker from "../../services/ResponseChecker";
import Request from "../../services/Request";
import router from "@/router";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage('success');
//URL list
const eachDataUrl = "family_member/getEach";
const allDataUrl = "family_member/get?";
const storeUrl = "family_member/store";
const updateUrl = "family_member/update";
const deleteUrl = "family_member/delete";
const savePresumptiveUrl = "family_member/savePresumptive";

const state = {
  familyMembers: [],
  familyMember: {},
};
const getters = {
  eachFamilyMember(state) {
    state.familyMember.sex = state.familyMember.sex + ``;
    return state.familyMember;
  },
};
const mutations = {
  SET_FAMILY_MEMBER(state, payload) {
    state.familyMembers = payload.data.data;
  },
  APPEND_FAMILY_MEMBER(state, payload) {
    state.familyMembers.push(payload.data.data);
  },
  REMOVE_FAMILY_MEMBER(state, payload) {
    state.familyMembers = state.familyMembers.filter((val) => {
      return val.id != payload;
    });
  },
  SET_EACH_FAMILY_MEMBER(state, payload) {
    state.familyMember = payload.data.data;
  },
};

const actions = {
  async getAll({ commit }, id) {
    const response = await Request.get_data(allDataUrl, { patient_id: id });
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) commit("SET_FAMILY_MEMBER", response);

  },
  async getEach({ commit }, id) {
    const response = await Request.get_data(eachDataUrl, { column: "id", value: id });
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) commit("SET_EACH_FAMILY_MEMBER", response);
  },
  async save({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("APPEND_FAMILY_MEMBER", response);
      notiMessage.displayNotiMsg('Saved Success')
      return true
    }
    return response;
  },

  async saveAsPresumptive(context, payload) {
    const response = await Request.post_data(savePresumptiveUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      router.push(`/presumptive-list/${response.data.data.township_id}`);
      notiMessage.displayNotiMsg('Family Member is saved as Presumptive')
      return true
    }
    return false;
  },

  async edit(context, payload) {
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg('Updated Success')
      return true
    }
    return false
  },

  async deleteFamilyMember({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_FAMILY_MEMBER", payload.id);
      notiMessage.displayNotiMsg('Deleted Success')
      return true
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
