import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage('success');
//URL List
const storeUrl = "patient/mdrtb_regiment/store";
const deleteUrl = "patient/mdrtb_regiment/delete";
const allDataUrl = "patient/mdrtb_regiment/get?";

const state = {
  mdrtbRegiments: [],
};
const getters = {};
const mutations = {
  SET_MDRTBREGIMENT(state, payload) {
    state.mdrtbRegiments = payload.data.data;
  },
  APPEND_MDRTBREGIMENT(state, payload) {
    state.mdrtbRegiments.push(payload.data.data);
  },
  REMOVE_MDRTBREGIMENT(state, payload) {
    state.mdrtbRegiments = state.mdrtbRegiments.filter((val) => {
      return val.id != payload;
    })
  }
};

const actions = {
  async store({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("APPEND_MDRTBREGIMENT", response);
      notiMessage.displayNotiMsg('Saved Success')
      return true
    }
    return false;
  },
  async delete({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_MDRTBREGIMENT", payload.id);
      notiMessage.displayNotiMsg('Deleted Success')
      return true
    }
    return false;
  },

  async getAll({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) commit('SET_MDRTBREGIMENT', response)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
