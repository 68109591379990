export default [
    {
        path: "/presumptive-referral",
        name: "presumptive-referral",
        component: () => import('@/views/presumptive/referral/PresumptiveReferral.vue'),
        meta: { forAuth: true },
    },
    {
        path: "/presumptive-referral/edit/:id",
        name: "presumptive-referral-edit",
        component: () => import('@/views/presumptive/referral/Edit.vue'),
        meta: { forAuth: true },
    },
    {
        path: "/presumptive",
        name: "presumptive",
        component: () => import("@/views/presumptive/Presumptive.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/presumptive-list",
        name: "presumptive-list",
        component: () => import("@/views/presumptive/PresumptiveList.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/presumptive-detail/:id",
        name: "presumptive-detail",
        component: () => import("@/views/presumptive/PresumptiveDetail.vue"),
        meta: { forAuth: true },
    },
]