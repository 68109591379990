export default [
    // VOLUNTEER SECTION
    {
        path: "/volunteer",
        name: "volunteer",
        component: () => import("@/views/volunteer/Volunteer.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/volunteer/trash",
        name: "volunteer-trash",
        component: () => import("@/views/volunteer/Trash.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/volunteer/create",
        name: "volunteer-create",
        component: () => import("@/views/volunteer/Create.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/volunteer/edit/:id",
        name: "volunteer-edit",
        component: () => import("@/views/volunteer/Edit.vue"),
        meta: { forAuth: true },
    },
]