export default [
  {
    path: "/report",
    name: "report",
    component: () => import("@/views/report/Report.vue"),
    meta: { forAuth: true },
  },

  {
    path: "/report/form7",
    name: "form7-report",
    component: () => import("@/views/report/Form7.vue"),
    meta: { forAuth: true },
  },
  {
    path: "/report/monthly",
    name: "monthly-report",
    component: () => import("@/views/report/Monthly.vue"),
    meta: { forAuth: true },
  },
  {
    path: "/report/monthly-list/",
    name: "monthly-list-report",
    component: () => import("@/views/report/MonthlyList.vue"),
    meta: {
      forAuth: true,
      //Breadcumps
      breadcrumps: [
        { text: "Report", to: "-2" },
        { text: "Monthly Report", to: "-1" },
        { text: "Monthly Report List", to: "", disabled: true },
      ],
    },
  },
  {
    path: "/report/volunteer/reported/",
    name: "volunteer-reported",
    component: () => import("@/views/report/volunteer/VolunteerReported.vue"),
    meta: {
      forAuth: true,
    },
  },
  {
    path: "/report/volunteer/dots/reported/",
    name: "volunteer-dotsreported",
    component: () => import("@/views/report/volunteer/VolDotsReported.vue"),
    meta: {
      forAuth: true,
    },
  },
  {
    path: "/report/volunteer/detail/",
    name: "volunteer-detail",
    component: () => import("@/views/report/volunteer/VolDetail.vue"),
    meta: {
      forAuth: true,
    },
  },
  {
    path: "/report/nomination-list",
    name: "nomination-list",
    component: () => import("@/views/report/NominationList.vue"),
    meta: {
      forAuth: true,
    },
  },
  {
    path: "/report/pre-enroll-nomination-list",
    name: "nomination-list",
    component: () => import("@/views/report/NominationList14A.vue"),
    meta: {
      forAuth: true,
    },
  },
];
