const state = {
  loading: false,
  localLoading: false,
};
const getters = {};

const mutations = {
  CHANGE_LOADING(state) {
    state.loading = !state.loading;
  },
  CHANGE_LOCAL_LOADING(state) {
    state.localLoading = !state.localLoading;
  },
};

const actions = {
  changeLoading({ commit }) {
    commit("CHANGE_LOADING");
  },
  changeLocalLoading({ commit }) {
    commit("CHANGE_LOCAL_LOADING");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
