<template>
  <div>
    <!-- hide only on large scroeen  -->
    <v-toolbar dense flat class="top-nav white--text d-flex d-lg-none">
      <v-app-bar-nav-icon @click.native="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-weight-400 dashboard--title">
        MDR Dashboard
      </v-toolbar-title>
    </v-toolbar>

    <v-navigation-drawer v-model="drawer" app>
      <v-sheet
        color="purple accent-4"
        class="d-flex flex-column justify-center pa-4"
      >
        <div class="white--text project--title text-uppercase text-center pb-1">
          MDR Project
        </div>
        <div class="py-2 pr-2">
          <v-img
            contain
            src="./../../assets/static-images/title.svg"
            aspect-ratio="3.9"
          />
        </div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon> mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/volunteer">
          <v-list-item-icon>
            <v-icon> mdi-account-heart-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Volunteers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/patient">
          <v-list-item-icon>
            <v-icon> mdi-account-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Patients</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/activity">
          <v-list-item-icon>
            <v-icon> mdi-charity</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Activity</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/presumptive">
          <v-list-item-icon>
            <v-icon> mdi-file-table-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Presumptive</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/dots">
          <v-list-item-icon>
            <v-icon> mdi-pill</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>DOTS Patient</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/dots-patient-export">
          <v-list-item-icon>
            <v-icon>  mdi-printer</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>DOT Patient Export</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/report">
          <v-list-item-icon>
            <v-icon> mdi-clipboard-file-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Report</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- hide only on large scroeen  -->
        <v-list-item class="d-lg-none" @click="showChangePassword">
          <v-list-item-icon>
            <v-icon> mdi-lock</v-icon>
          </v-list-item-icon>
        
        </v-list-item>

        <v-list-item class="d-lg-none" @click="logout">
          <v-list-item-icon>
            <v-icon> mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- / -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  computed: {},
  data: () => ({
    drawer: true,
  }),
  methods: {
    showChangePassword() {
      this.$store.dispatch("Admin/setDialog");
    },
    logout() {
      this.$store.dispatch("Admin/logout").then((isPass) => {
        if (isPass) this.$router.push("/login");
      });
    },
  },
};
</script>

<style scoped>
.project--title {
  letter-spacing: 3px;
  font-weight: 300 !important;
}
.v-list .v-list-item--active {
  color: var(--primary-color);
}
.v-list-group .v-list-group--active {
  color: grey !important;
}
.top-nav {
  box-shadow: var(--primary-box-shadow) !important;
  background-image: linear-gradient(
    to top,
    #aa00ff,
    #ad10fd,
    #af1bfc,
    #b223fa,
    #b42af9
  );
}
.v-toolbar__title {
  cursor: pointer;
}
.dashboard--title {
  font-weight: 300 !important;
  letter-spacing: 2px;
  font-size: 17px;
}
</style>
