import store from "../store";

export default class Loading {

    startLoading() {
        store.dispatch("Loading/changeLoading");
    }
    endLoading() {
        store.dispatch("Loading/changeLoading");
    }

}


