// export function addTextToInfectList(infectList) {
//   let newList = []
//   infectList.map((item) => {
//     let newItem = {};
//     let newInectObj = infectionText.find((e) => e.id == item.id);

//     item.title = newInectObj.text;
//     item.value = (item.value == 2) ? 'Yes' : 'No';
//     Object.defineProperty(newItem, item.title, {
//       value: item.value,
//     })

//     newList.push(newItem)
//   });
//   return newList;
// }

// function addTextToSeList(sideEffectList) {
//   let newList = []
//   newList = sideEffectList.map((item) => {
//     let newItem = {}
//     let newInectObj = seText.find((e) => e.id == item.id);
//     newItem.title = newInectObj.text;
//     if (item.value == 2) {
//       item.value =
//   }
//     return newItem;
//   });
//   return newList;
// }

export function getCustomFields(valueObj) {
  let newObj = {
    Date: valueObj.date,
    " MDRTB Code": valueObj.mdrtb_code,
    " MDRTB Year": valueObj.mdrtb_year,
    Township: valueObj.township?.name,
    "Township Group ": parseInt(valueObj.township.township_group_id) == 1 ? "MDR 1 " : parseInt(valueObj.township.township_group_id) == 2 ? "MDR 2" : '',
    Type: valueObj.type == 2 ? "By Phone" : "By Person",
    "Outside Township": valueObj.outside_township == 2 ? "Yes" : valueObj.outside_township == 1 ? "No" : '',
   
    "No of patient live in same household": valueObj.lived_patients_no,
  };

  return newObj;
}
