import { topicsOpt } from "../options";

function displayTopics(topics) {
  let topicsText = "";

  topics.map((element) => {
    let newElement = {};
    newElement = topicsOpt.find((item) => item.id == element.topic_id);
    if (topics.length > 1) topicsText += `,${newElement.text}`;
    else topicsText = newElement.text;
  });

  return topicsText;
}

function checkCount(count) {
  if (count) return count;
  return 0;
}

export function getCustomFields(valueObj) {
  let newObj = {
    Date: valueObj.date,
    Phone: valueObj.phone,
    " MDRTB Code": valueObj.mdrtb_code,
    " MDRTB Year": valueObj.mdrtb_year,
    Topics: displayTopics(valueObj.topics),
    "Household members less than 5": checkCount(valueObj.less_than_5_count),
    "Household members between 5 and 14": checkCount(
      valueObj.between_5_and_14_count,
    ),
    "Household members greater than 15": checkCount(valueObj.greate_than_15),
  };
  return newObj;
}
