export default [
    //ACTIVITY SECTION
    {
        path: "/activity",
        name: "activity",
        component: () =>
            import ("@/views/activity/Activity.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/activity-list/:month/:year",
        name: "activity-list",
        component: () =>
            import ("@/views/activity/ActivityList.vue"),
        meta: { forAuth: true },
    },
    // HE ACTIVITY SECTION
    {
        path: "/he/activity/:month/:year",
        name: "he",
        component: () =>
            import ("@/views/activity/HE/HeAct.vue"),
        meta: {
            forAuth: true,
            //Breadcumps
            breadcrumps: [
                { text: 'activity', to: '-2' },
                { text: 'activity list', to: '-1', params: true },
            ]
        },
    },
    {
        path: "/he/activity-uncompleted/",
        name: "he-uncompleted",
        component: () =>
            import ("@/views/activity/HE/UncompletedList.vue"),
        meta: { forAuth: true, },
    },
    {
        path: "/create/he/activity/:month/:year",
        name: "create-he",
        component: () =>
            import ("@/views/activity/HE/Create.vue"),
        meta: { forAuth: true, },
    },
    {
        path: "/edit/he/activity/:id/:month/:year",
        name: "edit-he",
        component: () =>
            import ("@/views/activity/HE/Edit.vue"),
        meta: { forAuth: true },
    },
    // HOME VISIT ACTIVITY SECTION
    {
        path: "/home-visit/activity/:month/:year",
        name: "home-visit",
        component: () =>
            import ("@/views/activity/home-visit/HomeVisitAct.vue"),
        meta: {
            forAuth: true,
            //Breadcumps
            breadcrumps: [
                { text: 'activity', to: '-2' },
                { text: 'activity list', to: '-1' },
            ]
        },
    },
    {
        path: "/home-visit/activity-uncompleted/",
        name: "home-visit-uncompleted",
        component: () =>
            import ("@/views/activity/home-visit/UncompletedList.vue"),
        meta: { forAuth: true, },
    },
    {
        path: "/create/home-visit/activity/:month/:year",
        name: "create-home-visit",
        component: () =>
            import ("@/views/activity/home-visit/Create.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/edit/home-visit/activity/:id/:month/:year",
        name: "edit-home-visit",
        component: () =>
            import ("../../views/activity/home-visit/Edit.vue"),
        meta: { forAuth: true },
    },
    // SUPERVISION ACTIVITY SECTION/
    {
        path: "/supervision-visit/activity/:month/:year",
        name: "supervision-visit",
        component: () =>
            import ("@/views/activity/supervision/SuperVisionAct.vue"),
        meta: {
            forAuth: true,
            //Breadcumps
            breadcrumps: [
                { text: 'activity', to: '-2' },
                { text: 'activity list', to: '-1' },
            ]
        },
    },
    {
        path: "/supervision/activity-uncompleted/",
        name: "supervision-uncompleted",
        component: () =>
            import ("@/views/activity/supervision/UncompletedList.vue"),
        meta: { forAuth: true, },
    },
    {
        path: "/create/supervision-visit/activity/:month/:year",
        name: "create-supervision-visit",
        component: () =>
            import ("@/views/activity/supervision/Create.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/edit/supervision-visit/activity/:id/:month/:year",
        name: "edit-supervision-visit",
        component: () =>
            import ("@/views/activity/supervision/Edit.vue"),
        meta: { forAuth: true },
    },
    // COUNSELING ACTIVITY SECTION
    {
        path: "/counseling/activity/:month/:year",
        name: "counseling",
        component: () =>
            import ("@/views/activity/counseling/CounselingAct.vue"),
        meta: {
            forAuth: true,
            //Breadcumps
            breadcrumps: [
                { text: 'activity', to: '-2' },
                { text: 'activity list', to: '-1' },
            ]
        },
    },
    {
        path: "/counseling/activity-uncompleted/",
        name: "counseling-uncompleted",
        component: () =>
            import ("@/views/activity/counseling/UncompletedList.vue"),
        meta: { forAuth: true, },
    },
    {
        path: "/create/counseling/activity/:month/:year",
        name: "create-counseling",
        component: () =>
            import ("@/views/activity/counseling/Create.vue"),
        meta: {
            forAuth: true,

        },
    },
    {
        path: "/edit/counseling/activity/:id/:month/:year",
        name: "edit-counseling",
        component: () =>
            import ("@/views/activity/counseling/Edit.vue"),
        meta: {
            forAuth: true,

        },
    },
]