export const typeOfPatientOpt = [
    { id: 1, text: "New" },
    { id: 2, text: "Non-converter" },
    { id: 3, text: "Rx after LTF " },
    { id: 4, text: "Rx after failure " },
    { id: 5, text: "Relapse" },
    {
        id: 6,
        text: "Rx after DRTB Rx",
    },
    { id: 7, text: "Others" },
    { id: null, text: " " },
];

export const typeOfTBOpt = [
    { id: 1, text: "Pulmonary" },
    { id: 2, text: "Extra Pulmonary" },
    { id: 3, text: "P+EP" },
    { id: null, text: " " },
];

export const lpaResultOpt = [
    { id: 1, text: "H Resistant" },
    { id: 2, text: "H Susceptible" },
    { id: 3, text: "R Resistant" },
    { id: 5, text: "R Susceptible" },
    { id: 6, text: "E Resistant" },
    { id: 7, text: "E Susceptible" },
    { id: 8, text: "S Resistant" },
    { id: 9, text: "S Susceptible" },
    { id: 10, text: "Amk Resistant" },
    { id: 11, text: "Amk Susceptible" },
    { id: 12, text: "Km Resistant" },
    { id: 13, text: "Km Susceptible" },
    { id: 14, text: "Cm Resistant" },
    { id: 15, text: "Cm Susceptible" },
    { id: 16, text: "FQ Resistant" },
    { id: 17, text: "FQ Susceptible" },
    { id: 18, text: "Contaminated" },
    { id: null, text: " " },
  ];

export const lpaResistantOpt = [
    { id: 1, text: "H Resistant" },
    { id: 3, text: "R Resistant" },
    { id: 6, text: "E Resistant" },
    { id: 8, text: "S Resistant" },
    { id: 10, text: "Amk Resistant" },
    { id: 12, text: "Km Resistant" },
    { id: 14, text: "Cm Resistant" },
    { id: 16, text: "FQ Resistant" },
]

export const lpaSusceptibleOpt = [
    { id: 2, text: "H Susceptible" },
    { id: 5, text: "R Susceptible" },
    { id: 7, text: "E Susceptible" },
    { id: 9, text: "S Susceptible" },
    { id: 11, text: "Amk Susceptible" },
    { id: 13, text: "Km Susceptible" },
    { id: 15, text: "Cm Susceptible" },
    { id: 17, text: "FQ Susceptible" },
    { id: 18, text: "Contaminated" },
    { id: null, text: " " },
];

export const dots_drugs = [
    { value: "bedaquiline", text: `ဘီတာကွီလင်း` },
    { value: "levofloxacin", text: `လီဗို` },
    { value: "linezolid", text: `လိုင်နီဇိုးလဒ်` },
    { value: "clofazimine", text: `ကလိုဖာဇမင်း` },
    { value: "ethionamide", text: `အီသီယိုးနမိုက်` },
    { value: "cycloserine", text: `ဆိုင်ကလိုစီရင်း` },
    { value: "pyrazinamide", text: `ပိုင်ရာဇင််းနမိုက်` },
    { value: "ethambutol", text: `အီသမ်ဗျူတာ` },
    { value: "isoniazid", text: `အိုင်စိုနိုင်ရဇစ်` },
    { value: "amikacin", text: `အမီကာစင်` },
    { value: "PAS", text: `ပါ့စ်` },
    { value: "delamanid", text: `ဒလားမနစ်` },
    { value: "moxifloxacin", text: `မောက်စီ` },
    { value: "pretomanid", text: `ပရီတိုးမနစ်` },
    { value: "rifampicin", text: `ရိုင်ဖမ်ပင်စင်` },
    
];

export const cultureResultOpt = [
    { id: 1, text: "Positive" },
    { id: 2, text: "Negative" },
    { id: 3, text: "Contaminated" },
    { id: null, text: " " },
];

export const cxrResultOpt = [
    { id: 1, text: "Normal" },
    { id: 2, text: "Abnormal" },
    { id: null, text: " " },
];

export const comorbidDieaseOpt = [
    { comorbid_dieases_id: '2', text: "DM" },
    { comorbid_dieases_id: '3', text: "Hypertension" },
    { comorbid_dieases_id: '4', text: "Hepatitis B" },
    { comorbid_dieases_id: '5', text: "Hepatitis C" },
    { comorbid_dieases_id: '6', text: "Others" },
    { comorbid_dieases_id: '7', text: "None" },
    { id: null, text: " " },
];

export const geneResultOpt = [
    { value: 1, text: "MTB not detected" },
    { value: 2, text: "MTB detected" },
    { value: 3, text: "Rifampicin resistant" },
    { value: 4, text: "Indeterminate" },
    { value: 5, text: "Invalid" },
];

export const hivResultOpt = [
    { id: 1, text: "Positive" },
    { id: 2, text: "Negative" },
    { id: 3, text: "Unknown" },
    { id: null, text: " " },
];

export const artRxOpt = [
    { id: 1, text: "No" },
    { id: 2, text: "Yes" },
    { id: 3, text: "Unknown" },
    { id: null, text: " " },
];

export const cptRxOpt = [
    { id: 1, text: "No" },
    { id: 2, text: "Yes" },
    { id: 3, text: "Unknown" },
    { id: null, text: " " },
];

export const finalOpt = [
    { id: 1, text: "Cured" },
    { id: 2, text: "Completed" },
    { id: 3, text: "Died" },
    { id: 4, text: "Lost to follow up" },
    { id: 5, text: "Failed" },
    { id: 6, text: "Moved to XDR" },
    { id: 7, text: "Not evaluated/ Transfer out" },
    { id: 8, text: "Moved to resident township" },
    { id: null, text: " " },
];

export const months = [
    { value: 1, text: "Jan" },
    { value: 2, text: "Feb" },
    { value: 3, text: "Mar" },
    { value: 4, text: "Apr" },
    { value: 5, text: "May" },
    { value: 6, text: "June" },
    { value: 7, text: "July" },
    { value: 8, text: "Aug" },
    { value: 9, text: "Sept" },
    { value: 10, text: "Oct" },
    { value: 11, text: "Nov" },
    { value: 12, text: "Dec" },
    { value: null, text: " " },
];

export const export_months = [
    { value: 1, text: "Jan" },
      { value: 2, text: "Feb" },
      { value: 3, text: "Mar" },
      { value: 4, text: "Apr" },
      { value: 5, text: "May" },
      { value: 6, text: "June" },
      { value: 7, text: "July" },
      { value: 8, text: "Aug" },
      { value: 9, text: "Sept" },
      { value: 10, text: "Oct" },
      { value: 11, text: "Nov" },
      { value: 12, text: "Dec" },
      { value: 13, text: "Quarter 1" },
      { value: 14, text: "Quarter 2" },
      { value: 15, text: "Quarter 3" },
      { value: 16, text: "Quarter 4" },
      { value: 17, text: "S1" },
      { value: 18, text: "S2" },
      { value: null, text: " " },
  ];

export const supportTypes = [
    { value: 1, text: "signature" },
    { value: 2, text: "ATM" },
    { value: 3, text: "Mobile" },
];

export const topicsOpt = [
    { id: 1, text: "Treatment Adherence" },
    { id: 2, text: "Psychological Support" },
    { id: 3, text: "Contact Investigation" },
    { id: 4, text: "Infection Control" },
    { id: 5, text: "Checking Side Effect" },
    { id: 6, text: "Service Directory" },
];

export const ordinalMonths = [
    { id: "1st", text: "1st" },
    { id: "2nd", text: "2nd" },
    { id: "3rd", text: "3rd" },
    { id: "4th", text: "4th" },
    { id: "5th", text: "5th" },
    { id: "6th", text: "6th" },
    { id: "7th", text: "7th" },
    { id: "8th", text: "8th" },
    { id: "9th", text: "9th" },
    { id: "10th", text: "10th" },
    { id: "11th", text: "11th" },
    { id: "12th", text: "12th" },
    { id: "13th", text: "13th" },
    { id: "14th", text: "14th" },
    { id: "15th", text: "15th" },
    { id: "16th", text: "16th" },
    { id: "17th", text: "17th" },
    { id: "18th", text: "18th" },
    { id: "19th", text: "19th" },
    { id: "20th", text: "20th" },
    { id: "", text: "" },
];

export const modifications_name = [
    { id: "Levofloxacin", text: "Levofloxacin" },
    { id: "Moxifloxacin", text: "Moxifloxacin" },
    { id: "Bedaquiline", text: "Bedaquiline" },
    { id: "Linezolid", text: "Linezolid" },
    { id: "Clofazamine", text: "Clofazamine" },
    { id: "Cycloserine", text: "Cycloserine" },
    { id: "Ethambutol", text: "Ethambutol" },
    { id: "Delamanid", text: "Delamanid" },
    { id: "Pyrazinamide", text: "Pyrazinamide" },
    { id: "Amikacin", text: "Amikacin" },
    { id: "Ethionamide", text: "Ethionamide" },
    { id: "P-aminosalicylic acid", text: "P-aminosalicylic acid" },
    { id: "Isoniazid", text: "Isoniazid" },
    { id: "Pretomanid", text: "Pretomanid" },
    { id: "Other", text: "Other" },
    { id: "", text: "" },
];