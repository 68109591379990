<template>
  <v-app>
    <side-nav v-if="isLoggedIn" />
    <v-main id="main">
      <top-nav v-if="isLoggedIn" />
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <change-password :dialog="dialog" />
  </v-app>
</template>

<script>
//import SnackBar from "@/components/notification/SnackBar";
import SideNav from "@/components/navigation/SideNav";
import TopNav from "@/components/navigation/TopNav";

import ChangePassword from "@/components/admin/ChangePassword";

import { mapState, mapActions } from "vuex";
export default {
  name: "App",

  components: {
    SideNav,
    TopNav,
    //SnackBar,
    ChangePassword,
  },
  computed: {
    ...mapState("Admin", ["isLoggedIn"]),
    ...mapState("Admin", ["dialog"]),
  },
  methods: {
    ...mapActions("Admin", ["getAdmin"]),
  },
  data: () => ({
    //
  }),
  created() {
    // if (this.isLoggedIn) this.getAdmin();

    document.title = "MDR FO Dashbord";
  },
};
</script>

<style>
@import "./assets/css/table.css";
@import "./assets/css/form.css";
@import "./assets/css/app.css";
@import "./assets/css/signature.css";
@import "./assets/css/feedback.css";
</style>
