import Vue from "vue";

const state = {
  notifications: {},
};
const getters = {};

const mutations = {
  PUSH(state, notification) {
    state.notification = notification;
  },
};

const actions = {
  add({ commit }, notification) {
    if (notification.type == "success") {
      Vue.$toast(notification.message, {
        timeout: 2000,
        type: "success",
        position: "top-center",
        onClose: () => console.log("closed!"),
      });
    } else {
      Vue.$toast(notification.message, {
        timeout: 2000,
        type: "error",
        position: "top-center",
        onClose: () => console.log("closed!"),
      });
    }
    commit("PUSH", notification);
  },
  remove({ commit }) {
    commit("DELETE");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
