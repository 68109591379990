import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage("success");

const errorMessage = new NotiMessage("error");  

//URL List
const eachDataUrl = "patient/enroll/support/getEach?";
const allDataUrl = "patient/enroll/support/get?";
const storeUrl = "patient/enroll/support/store";
const deleteUrl = "patient/enroll/support/delete";
const updateEnrollInfoUrl = "patient/enroll/information/update";
const updateConclusionUrl = "patient/enroll/finish_information/update";

//clear all state
const getDefaultState = () => {
  return {
    enrollEach: {},
  };
};

const state = {
  enrollData: [],
  enrollEach: {},
};
const getters = {};
const mutations = {
  SET_ENROLL_DATA(state, payload) {
    state.enrollData = payload;
  },
  SET_ENROLL_EACH(state, payload) {
    state.enrollEach = payload;
  },
  ADD_ENROLL(state, payload) {
    state.enrollData.push(payload);
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  REMOVE_ENROLL_DATA(state, payload) {
    state.enrollData = state.enrollData.filter((val) => {
      return val.id != payload;
    });
  },
};


const actions = {
  async getEnrollEach({ commit }, id) {
    const params = { column: "id", value: id };
    const response = await Request.get_data(eachDataUrl, params);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_ENROLL_EACH", response.data.data);
      return true;
    }
    return false;
  },
  async getEnroll({ commit }, id) {
    const params = { patient_id: id };
    const response = await Request.get_data(allDataUrl, params);
    const responseChecker = new ResponseChecker(response);
    console.log(response)
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_ENROLL_DATA", response.data.data);
      return true;
    }
    return false;
  },
  async createEnroll(context, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      window.location.reload();
      notiMessage.displayNotiMsg("Saved Success");
      return true;
    }
    errorMessage.displayNotiMsg(response.data.message);
    return false;
  },
  async updateEnrollInfo(context, payload) {
    const response = await Request.post_data(updateEnrollInfoUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg("Update Enroll Info Success");
      return true;
    }
    errorMessage.displayNotiMsg(response.data.message);
    return false;
  },
  async updateConclusion(context, payload) {
    const response = await Request.post_data(updateConclusionUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg("Update final Information Success");
      return true;
    }
    return false;
  },
  async delete({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_ENROLL_DATA", payload.id);
      notiMessage.displayNotiMsg("Deleted Success");
      return true;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
