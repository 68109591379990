import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";
import { getCustomFields } from "../../services/export-excel/ExportSupervisionField";

const notiMessage = new NotiMessage("success");
//URL List
const eachDataUrl = "activity/supervision_visit/getEach?";
const allDataUrl = "activity/supervision_visit/get?";
const storeUrl = "activity/supervision_visit/store";
const updateUrl = "activity/supervision_visit/update";
const deleteUrl = "activity/supervision_visit/delete";

const getDefaultState = () => {
  return {
    supervisionEachData: {},
  };
};

const state = {
  supervisionData: [],
  supervisionEachData: {},
};
const getters = {
  exportFieldsHandler(state) {
    let supervisionData = [];
    if (state.supervisionData.length > 0) {
      state.supervisionData.map((element) => {
        supervisionData.push(getCustomFields(element));
      });
    }
    return supervisionData;
  },
};
const mutations = {
  SET_SUPER_VISION_DATA(state, payload) {
    state.supervisionData = payload.data.data;
  },
  SET_SUPER_VISION_EACH(state, payload) {
    state.supervisionEachData = payload.data.data;
  },
  ADD_SUPER_VISION_DATA(state, payload) {
    state.supervisionData.push(payload.data.data);
  },
  REMOVE_SUPER_VISION_DATA(state, payload) {
    const supervisionData = state.supervisionData.filter(
      (item) => item.id != payload.id,
    );
    state.supervisionData = supervisionData;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async getAll({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_SUPER_VISION_DATA", response);
  },
  async getUncompletedList({ commit }, payload) {
    const response = await Request.get_data(
      "activity/supervision_visit/getUncompletedList?",
      { ids: JSON.stringify(payload.ids) }
    );
    console.log(response);
    commit("SET_SUPER_VISION_DATA", response);
  },
  async getEach({ commit }, id) {
    commit("resetState");
    const response = await Request.get_data(eachDataUrl, {
      column: "id",
      value: id,
    });
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("SET_SUPER_VISION_EACH", response);
      return true;
    }
    return false;
  },
  async create({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("ADD_SUPER_VISION_DATA", response);
      notiMessage.displayNotiMsg("Saved Success");
      return true;
    }
    return false;
  },
  async update(context, payload) {
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg("Updated Success");
      return true;
    }
    return false;
  },
  async delete({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    if (response.data.success) {
      commit("REMOVE_SUPER_VISION_DATA", payload);
      notiMessage.displayNotiMsg("Deleted Success");
      return true;
    }
    return;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
