import Request from "../../services/Request";
import router from "@/router";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage("success");

//URL List
const eachDataUrl = "/dots_patient/activit/getEach";
const allDataUrl = "/dots_patient/activity/get?";
const storeUrl = "/dots_patient/activity/store";
const updateUrl = "/dots_patient/activity/update";

const state = {
  datas: [],
  data: {},
};
const getters = {};
const mutations = {
  SET_DATA(state, payload) {
    state.datas = payload;
  },
  APPEND_DATA(state, payload) {
    state.datas.push(payload);
  },
  REMOVE_DATA(state, payload) {
    state.datas = state.datas.filter((val) => {
      return val.id != payload;
    });
  },
  SET_EACH_DATA(state, payload) {
    state.data = payload;
  },
};

const actions = {
  async getAll({ commit }, params) {
    const response = await Request.get_data(allDataUrl, params);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_DATA", response.data.data);
  },
  async getEach({ commit }, params) {
    const response = await Request.get_data(eachDataUrl, params);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_DATA", response.data.data);
  },
  async save(context, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      router.push(`/dots-detail/${payload.dots_patient_id}`);
      notiMessage.displayNotiMsg("Saved Success");
      return true;
    }
    return false;
  },
  async update(context, payload) {
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg("Updated Success");
      return true;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
