
export default class ValidateChecker {
    _field = {}

    constructor(field) {
        this._field = field
    }

    checkRequired() {
        if (this._field.$params.required || !this._field.$params.required)
            if (this._field.required == false) return true
    }

    checkEmail() {
        if (this._field.$params.email)
            if (this._field.email == false) return true
    }

    checkMinLength() {
        if (this._field.$params.minLength)
            if (this._field.minLength == false) return true
    }

    checkMaxLength() {
        if (this._field.$params.maxLength)
            if (this._field.maxLength == false) return true
    }

    checkBetween() {
        if (this._field.$params.between)
            if (this._field.between == false) return true
    }

    checkSameAs() {
        if (this._field.$params.sameAs)
            if (this._field.sameAs == false) return true
    }
}