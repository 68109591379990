export default class ResponseChecker {
  _response = {};
  _responseStatus = null;

  constructor(response) {
    this._response = response;
  }

  checkResponse() {
    switch (this._response.data.code) {
      case 404:
        this._responseStatus = "Not Found";
        break;
      case 422:
        this._responseStatus = "Unprocessable Entity ";
        break;
      case 500:
        this._responseStatus = "Internal Server Error";
        break;
      case 401:
        this._responseStatus = "failed";
        break;
      default:
        this._responseStatus = "success";
        break;
    }
  }
  isResponseStatusSuccess() {
    if (this._responseStatus == "success") return true;
  }
}
