export default [
  // PATIENT SECTION
  {
    path: "/patient",
    name: "patient",
    component: () => import("@/views/patient/Patient.vue"),
    meta: { forAuth: true },
  },
  {
    path: "/patient/create",
    name: "patient-create",
    component: () => import("@/views/patient/Create.vue"),
    meta: { forAuth: true },
  },
  {
    path: "/patient/edit/:id",
    name: "patient-edit",
    component: () => import("@/views/patient/Edit.vue"),
    meta: { forAuth: true },
  },
  //DELEGATED PERSON SECTION
  {
    path: "/delagated-person/:patient_id",
    name: "delagated-person",
    component: () => import("@/views/patient/delagated-person/DelPerson.vue"),
    meta: { forAuth: true },
  },
  {
    path: "/create/delagated-person/:patient_id",
    name: "create-delagated-person",
    component: () => import("@/views/patient/delagated-person/Create.vue"),
    meta: { forAuth: true },
  },
  {
    path: "/edit/delagated-person/:patient_id",
    name: "edit-delagated-person",
    component: () => import("@/views/patient/delagated-person/Edit.vue"),
    meta: { forAuth: true },
  },
  // PRE ENROLL SECTION
  {
    path: "/pre-enroll/:id",
    name: "pre-enroll",
    component: () => import("@/views/patient/pre-enroll/PreEnroll.vue"),
    meta: {
      forAuth: true,
      //Breadcumps
      breadcrumps: [
        { text: "Patient List", to: "-2" },
        { text: "Patient Detail", to: "-1" },
        { text: "Pre Enroll Page", to: "", disabled: true },
      ],
    },
  },
  // ENROLL SECTION
  {
    path: "/enroll/:id",
    name: "enroll",
    component: () => import("@/views/patient/enroll/Enroll.vue"),
    meta: {
      forAuth: true,
      //Breadcumps
      breadcrumps: [
        { text: "Patient List", to: "-2" },
        { text: "Patient Detail", to: "-1" },
        { text: "Enroll Page", to: "", disabled: true },
      ],
    },
  },
  {
    path: "/enroll/info/:patientId",
    name: "enroll-info",
    component: () => import("@/views/patient/enroll/EnrollInfo.vue"),
    meta: { forAuth: true },
  },
  {
    path: "/enroll/clu/:patientId",
    name: "enroll-clu",
    component: () => import("@/views/patient/enroll/EnrollClu.vue"),
    meta: { forAuth: true },
  },
  {
    path: "/patient/mdrtb_regiment/:id",
    name: "patient-mdrtb_regiment",
    component: () => import("@/views/patient/mdrtbregiment/MdrtbRegiment.vue"),
    meta: { forAuth: true },
  },

  // PATIENT FAMILY
  {
    path: "/patient-family/:patient_id",
    name: "PatientFamily",
    component: () => import("@/views/patient/family/PatientFamily.vue"),
    meta: { forAuth: true },
  },

  {
    path: "/patient-family-edit/:id",
    name: "patient-family-edit",
    component: () => import("@/views/patient/family/Edit.vue"),

    meta: {
      forAuth: true,
    },
  },
];
