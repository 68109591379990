import axios from "axios";

// BACKEND URL
const url = "https://api.mdr.tbtheunion.com/api/pa";
// const url = "https://localhost:8000/api/pa";
const apiClient = axios.create({
    baseURL: url,
});

apiClient.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
    "MDR_DASHBOARD_PA_TOKEN",
  )}`;
    //Config is the object of AxiosRequestConfig which contains URL, base URL, headers request, body data, response type, timeout, etc.
    return config;
});

apiClient.interceptors.response.use((config) => {
    return config;
});

export default apiClient;