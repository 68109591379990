import {
  typeOfPatientOpt,
  typeOfTBOpt,
  lpaResultOpt,
  cultureResultOpt,
  cxrResultOpt,
  comorbidDieaseOpt,
  // geneResultOpt,
  hivResultOpt,
  artRxOpt,
  cptRxOpt,
  finalOpt,
  months,
  supportTypes,
} from "./options.js";
import moment from "moment";


function getTypeOfTbPatient(value) {
  var result = typeOfPatientOpt?.find((val) => {
    return val.id == value.type_of_patient;
  });
  return result ? result.text : "";
}
function getTypeOfTb(value) {
  var result = typeOfTBOpt?.find((val) => {
    return val.id == value.type_of_tb;
  });
  return result ? result.text : "";
}
function getLpaResult(value) {
  var result = lpaResultOpt?.find((val) => {
    return val.id == value.lpa_result;
  });
  return result ? result.text : "";
}
function getCultureResult(value) {
  var result = cultureResultOpt?.find((val) => {
    return val.id == value.culture_result;
  });
  return result ? result.text : "";
}
function displayDotsStartDate(value) {
  if (value.dots_patient[0]) {
    return value.dots_patient[0].dots_startdate;
  }
  return null;
}
function displayDotsEndDate(value) {
  if (value.dots_patient[0]) {
    return value.dots_patient[0].dots_enddate;
  }
  return null;
}
function getCxrResult(value) {
  var result = cxrResultOpt.find((val) => {
    return val.id == value.cxr_result;
  });
  return result ? result.text : "";
}
function calculateAge(dob) {
  let currentDate = new Date();
  let birthDate = new Date(dob);
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  let month = currentDate.getMonth() - birthDate.getMonth();
  if (
    month < 0 ||
    (month === 0 && currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
}
function getComboidDisease(value) {
  var result = "";
  value.comorbid_diseases.map((val) => {
    result += `${
      comorbidDieaseOpt.find(
        (value) => value.comorbid_dieases_id == val.comorbid_diseases_id
      )?.text
    },`;
  });
  return result;
}
// function getHivResult(value) {
//   switch (value.hiv_status) {
//     case 1:
//       return "No";

//     case 2:
//       return "Yes";

//     default:
//       return "";
//   }
// }
function getTreatmentFinish(value) {
  switch (value.treatment_finish) {
    case 1:
      return "Stop";
    case 2:
      return "Finish";
    default:
      return "On-going";
  }
}

function getSupportMonth(month) {
  var result = months.find((val) => {
    return val.value == month;
  });
  return result ? result.text : "";
}

function getSupportItem({
  returnObj,
  index,
  supportMonth,
  supportYear,
  support,
  date,
  giveType,
  type,
  nominated,
  nominated_month,
  nominated_year
}) {
  var monthKey = `${type} Month ${index}`;
  var yearKey = `${type} Year ${index}`;
  var supportKey = `${type} Support ${index}`;
  var dateKey = `${type} Date ${index}`;
  var typeKey = `${type} Type ${index}`;
  let nominatedKey = `${type} Nominated ${index}`;
  

  var supObj = {};
  supObj[monthKey] = supportMonth;
  supObj[yearKey] = supportYear;
  supObj[supportKey] = support;
  supObj[dateKey] = date;
  supObj[typeKey] = giveType;
  supObj[nominatedKey] = nominated;
  if(type === 'Pre'){
  let nominatedMonth = `${type} Nominated Month ${index}`;
  let nominatedYear = `${type} Nominated Year ${index}`;
  supObj[nominatedMonth] = nominated_month;
  supObj[nominatedYear] = nominated_year;
  }
  
  return { 
    ...returnObj, 
   ...supObj
  };
}

const enrollData = (values, type, treatment_startdate,maxEnrollCount) => {
  let returnObj = {};
  let valuesObj = {}
  maxEnrollCount = 20;
  for (let index = 1; index < maxEnrollCount; index++) {
    returnObj = getSupportItem({
      returnObj: returnObj,
      index: index,
      supportMonth: "",
      supportYear: "",
      date: "",
      support: "",
      typeText: "",
      type: type,
      nominated: "",
    });
  }
 
  values.map((element) => {
    const result = supportTypes.find((val) => {
      return val.value == element.type;
    });
    const typeText = result ? result.text : ""; 

    const treatmentDate = moment(treatment_startdate).format("YYYY-MM-DD");
    //calculate month count base on treatment start date
    const supportMonthYear = moment(
      new Date(element.support_year, element.support_month - 1, 20)
    ).format("YYYY-MM-DD");
    const monthDiffCount = Math.abs(
      Math.floor(moment(treatmentDate).diff(supportMonthYear, "months", true))
    );
    valuesObj = getSupportItem({
      returnObj: valuesObj,
      index: monthDiffCount === 0 ? monthDiffCount + 1 : monthDiffCount,
      supportMonth: getSupportMonth(element.support_month),
      supportYear: element.support_year,
      date: element.date,
      support: element.support == 1 ? "No" : "Yes",
      typeText: typeText,
      type: type,
      nominated: element.nominated == 1 ? "No" : "Yes",
    });
  });
  const newObj = {...returnObj, ...valuesObj}
  return newObj;
};

function getEnrollData(values, type) {
  var returnObj = {};

  values.map((element, index) => {
    var result = supportTypes.find((val) => {
      return val.value == element.type;
    });
    var typeText = result ? result.text : "";
    returnObj = getSupportItem({
      returnObj: returnObj,
      index: index + 1,
      supportMonth: getSupportMonth(element.support_month),
      supportYear: element.support_year,
      date: element.date,
      support: element.support == 1 ? "No" : "Yes",
      typeText: typeText,
      type: type,
      nominated: element.nominated == 1 ? "No" : "Yes",
      nominated_month:element.nominated_month,
      nominated_year:element.nominated_year
    });
  });
  if (type == "Pre" && values.length < 2) {
    returnObj = {
      ...returnObj,
      ...{
        "Pre Month 2": "",
        "Pre Year 2": "",
        "Pre Support 2": "",
        "Pre Date 2": "",
        "Pre Type 2": "",
      },
    };
  }
  return returnObj;
}

export function getCustomFields(value, maxEnrollCount) {
  let enroll_supports = [];
  let counter = 0;
  value.enroll_supports.map((val) => {
    if (counter > 0) {
      let realIndex = enroll_supports.length - 1;

      if (enroll_supports[realIndex].support_month != val.support_month) {
        enroll_supports.push(val);
      } else {
        enroll_supports[realIndex] = val;
      }
    } else {
      enroll_supports.push(val);
    }
    counter++;
  });
  value.enroll_supports = enroll_supports;

  const exportFields =  {
    Name: value.name,
    DOB: value.dob,
    Age: calculateAge(value.dob),
    Sex: value.sex == 1 ? "M" : value.sex == 2 ? "F" : "",
    Address: value.address,
    Township:value.township.name,
    "Xpert Code": value.gene_code,
    // "Xpert Result": geneResultOpt.find((g) => g.id == value.gene_result).text,
    "Lab Test Name": value.lab_test_name,
    "Lab Code": value.lab_code,
    "Lab Date": value.lab_date,
    "Lab Result": value.lab_result,
    "Pre-Enroll": value.pre_enroll == 1 ? "Yes" : "No",
    "Mono-Poly/SG": value.mdrtb_monopoly == 1 ? "No" : value.mdrtb_monopoly == 2 ? 'Yes' : value.mdrtb_monopoly == 3? 'SG': '',
    ...getEnrollData(value.pre_enroll_supports, "Pre"),
    "Outside Township":
      value.outside_tsp == 1 ? "No" : value.outside_tsp == 2 ? "Yes" : "",
    "Transfer In": value.t_in === 1 ? "No" : "Yes",
    "Type Of Patient": getTypeOfTbPatient(value),
    "Type Of TB": getTypeOfTb(value),
    "LPA Result": getLpaResult(value),
    "LPA Date": value.lpa_date,
    "Culture Result": getCultureResult(value),
    "Culture Date": value.culture_date,
    "CXR Date": value.cxr_date,
    "CXR Result": getCxrResult(value),
    "Comorbid Disease": getComboidDisease(value),
    "HIV Date": value.hiv_status == 2 ? value.hiv_date : "",
    "HIV Result":
      value.hiv_status == 2
        ? hivResultOpt.find((val) => val.id == value.hiv_result)?.text
        : "",
    "ART RX":
      value.hiv_result == 1
        ? artRxOpt.find((val) => val.id == value.art_rx)?.text
        : "",
    "ART Date": value.art_rx == 2 ? value.art_rx_date : "",
    "CPT RX":
      value.hiv_result == 1
        ? cptRxOpt.find((val) => val.id == value.cpt_rx)?.text
        : "",
    "CPT Date": value.cpt_rx == 2 ? value.cpt_rx_date : "",
    ...enrollData(
      value.enroll_supports,
      "Enroll",
      value.treatment_startdate,
      maxEnrollCount
    ),
    "Treatment Finish": getTreatmentFinish(value),
    "Finished Date": value.finished_date,
    "Final Outcome": value.final_outcome
      ? finalOpt.find((val) => val.id == value.final_outcome)?.text
      : "",
    "Outcome Date": value.final_outcome_date,
    "Issued Date": value.issue_date,
    "DOT Start Date": displayDotsStartDate(value),
    "DOT End Date": displayDotsEndDate(value),
    "DOT Status": value.is_dots_patient ? 'Yes' : 'No DOT Patient'
    // "DOT End Date":value.dots_patient[0].dots_enddate ? value.dots_patient[0].dots_enddate : null,
    // "MDRTB Code": value.reg_unique_code,
    // "Treatment Startdate": value.treatment_startdate,
    // "Outside Township Name": value.outside_tsp,
    // "Transfer In Date": value.t_in_date ?? "",
    // "Transfer In Area":
    //   value.t_in == 2
    //     ? value.t_in_type == 1
    //       ? "Within Project"
    //       : "Outside"
    //     : "",
    // "Transfer In Township":
    //   value.t_in == 2 ? value.transfer_in_township?.short_code : "",
    // "HIV Test": getHivResult(value),
    // Phone: value.is_dots_patient ? value.dots_patient.phone : "",
    // "Body Weight": value.is_dots_patient ? value.dots_patient.body_weight : "",
  };
  if(value.is_dots_patient){
    exportFields['DOT APP Status(Yes/No)'] = value.dots_patient[0].dots_app_status === '2' ? 'Yes' : 'No'
  }

  if(value.family_members){
    value.family_members.forEach((member,index) => {
      exportFields[`Family Member Name${index + 1}`] = member.name;
      exportFields[`Family Member Age${index + 1}`] = member.age;
    });
  }
  return exportFields;
}
