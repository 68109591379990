import {export_months , months, supportTypes } from "./../../services/options.js";
const state = {
    nominatedOpt: [
        { value: 2, text: "Yes" },
        { value: 1, text: "No" },
        { value: null, text: " " },
    ],
    delagatedOpt: [
        { id: 2, text: "Yes" },
        { id: 1, text: "No" },
        { id: null, text: " " },
    ],
    supports: [
        { value: 2, text: "Yes" },
        { value: 1, text: "No" },
        { value: null, text: " " },
    ],
    types: supportTypes,
    months: months,
    export_months :export_months,
    years: [
        { text: 2020 },
        { text: 2021 },
        { text: 2022 },
        { text: 2023 },
        { text: 2024 },
        { value: null, text: " " },
    ],
    dotsSupervisionStatus: [
        { value: 1, text: "Stop" },
        { value: 2, text: "On-going" },
    ],
    monthTypes: [
        { value: 1, text: "Month 1" },
        { value: 2, text: "Month 2" },
    ],
    caseFoundAts: [{
            id: 1,
            name: "HE",
            status: 1,
            created_at: null,
            updated_at: null,
        },
        {
            id: 2,
            name: "TBCT",
            status: 1,
            created_at: null,
            updated_at: null,
        },
        {
            id: 3,
            name: "DRTB CT",
            status: 1,
            created_at: null,
            updated_at: null,
        },
        {
            id: 4,
            name: "Community",
            status: 1,
            created_at: null,
            updated_at: null,
        },
        {
            id: 5,
            name: "Follow up",
            status: 1,
            created_at: null,
            updated_at: null,
        },
        {
            id: 6,
            name: "Missing",
            status: 1,
            created_at: null,
            updated_at: null,
        },
    ],
    InitialOpt: [{ id: 1, text: "M" }],
    relationOpt: [
        { id: 1, text: "Household" },
        { id: 2, text: "Non Household" },
    ],
};
const getters = {};

const mutations = {};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};