import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import activity from "./routes/activity";
import patient from "./routes/patient";
import volunteer from "./routes/volunteer";
import auth from "./routes/auth";
import dots from "./routes/dots";
import report from "./routes/report";
import presumptive from "./routes/presumptive";

Vue.use(VueRouter);

const routes = [
  ...auth,
  ...patient,
  ...activity,
  ...volunteer,
  ...dots,
  ...report,
  ...presumptive,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((toRoute) => toRoute.meta.forAuth)) {
    if (store.state.Admin.isLoggedIn) next();
    else next("/login");
  }
  //isLoggedIn state true but want to access the login page it will redirect to home page
  else if (store.state.Admin.isLoggedIn && to.name == "Login") next("/");

  next();
});

export default router;
