export default [
    // AUTH SECTION
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/auth/Login.vue"),

    },
    {
        path: "/network-error",
        name: "NetworkError",
        component: () => import("@/views/NetworkError.vue"),
    },
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home.vue"),
        meta: { forAuth: true },
    },
]