import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";
import { getCustomFields } from "../../services/export-excel/ExportHomeVisitField";

const notiMessage = new NotiMessage("success");
// const errorMessage = new NotiMessage("danger");
//URL List
const eachDataUrl = "activity/home_visit/getEach?";
const allDataUrl = "activity/home_visit/get?";
const storeUrl = "activity/home_visit/store";
const updateUrl = "activity/home_visit/update";
const deleteUrl = "activity/home_visit/delete";

const checkUrl = "activity/home_visit/check-code";

const getDefaultState = () => {
  return {
    homeVisitEachData: {},
  };
};

const state = {
  homeVisitData: [],
  homeVisitEachData: {},
  homeVisitCollection: {},
};

const getters = {
  exportFieldsHandler(state) {
    let homevisitData = [];
    if (state.homeVisitData.length > 0) {
      state.homeVisitData.map((element) => {
        homevisitData.push(getCustomFields(element));
      });
    }
    return homevisitData;
  },
};

const mutations = {
  SET_HOME_VISIT_DATA(state, payload) {
    state.homeVisitData = payload.data.data;
  },
  SET_INFECTION_ALL_DATA(state, payload) {
    state.homeVisitCollection = payload;
  },
  SET_SE_LIST_ALL_DATA(state, payload) {
    state.homeVisitCollection = { ...state.homeVisitCollection, ...payload };
  },
  SET_HOME_VISIT_EACH(state, payload) {
    state.homeVisitEachData = payload.data.data;
  },
  ADD_HOME_VISIT_DATA(state, payload) {
    state.homeVisitData.push(payload.data.data);
  },
  CHECK_HOME_VISIT_DATA(state, payload) {
    state.homeVisitData.push(payload.data.data);
  },
  REMOVE_HOME_VISIT_DATA(state, payload) {
    const homeVisitData = state.homeVisitData.filter(
      (item) => item.id != payload.id
    );
    state.homeVisitData = [...homeVisitData];
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async getAll({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_HOME_VISIT_DATA", response);
  },
  async getUncompletedList({ commit }, payload) {
    const response = await Request.get_data(
      "activity/home_visit/getUncompletedList?",
      { ids: JSON.stringify(payload.ids) }
    );
    console.log(response);
    commit("SET_HOME_VISIT_DATA", response);
  },
  setInfectionAlldata({ commit }, payload) {
    commit("SET_INFECTION_ALL_DATA", payload);
  },
  setSeFormData({ commit }, payload) {
    commit("SET_SE_LIST_ALL_DATA", payload);
  },
  setSeRemark({ commit }, payload) {
    commit("SET_SE_REMARK", payload);
  },
  
  async checkCode({ commit }, payload) {
    const response = await Request.post_data(checkUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("CHECK_HOME_VISIT_DATA", response);
     
      // if(response!=false)
      // {
      //   notiMessage.displayNotiMsg("Success");
      // }
      // else{
      //   {
      //     errorMessage.displayNotiMsg("Invalid Code or Year");
      //   }
      // }
      return response;
    }
    return false;
  },
  

  async getEach({ commit }, id) {
    commit("resetState");
    const response = await Request.get_data(eachDataUrl, {
      column: "id",
      value: id,
    });
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_HOME_VISIT_EACH", response);
  },
  async create({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("ADD_HOME_VISIT_DATA", response);
      notiMessage.displayNotiMsg("Saved Success");
      return true;
    }
    return false;
  },
  async update(context, payload) {
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg("Updated Success");
      return true;
    }
    return false;
  },
  async delete({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_HOME_VISIT_DATA", payload);
      notiMessage.displayNotiMsg("Deleted Success");
      return true;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
