import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage('success');

 
//URL List
const eachDataUrl = "volunteer/getEach?";
const allDataUrl = "volunteer/get?";
const trashUrl = "volunteer/trash?";
const recoverUrl = "volunteer/recover";
const storeUrl = "volunteer/store";
const updateUrl = "volunteer/update";
const deleteUrl = "volunteer/delete";


const getDefaultState = () => {
  return {
    volunteer: {},
  };
};

const state = {
  volunteers: [],
  volunteer: {},
};
const getters = {};
const mutations = {
  SET_VOLUNTEERS(state, payload) {
    state.volunteers = payload.data.data;
  },
  SET_VOLUNTEER(state, payload) {
    state.volunteer = payload.data.data;
  },
  ADD_VOLUNTEER(state, payload) {
    state.volunteers.push(payload.data.data);
  },
  REMOVE_VOLUNTEER(state, payload) {
    const volunteers = state.volunteers.filter((item) => item.id != payload.id);
    state.volunteers = [...volunteers];
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async getVolunteers({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload)
   
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_VOLUNTEERS", response);
  },
  async getVolunteerByTwnId({ commit }, id) {
    const response = await Request.get_data(allDataUrl, { township_id: id })
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit('SET_VOLUNTEERS', response)
  },
  async getTrashedVolunteer({ commit }, payload) {
    const response = await Request.get_data(trashUrl, payload)
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_VOLUNTEERS", response);
  },
  async getVolunteer({ commit }, id) {
    commit("resetState");
    const response = await Request.get_data(eachDataUrl, { column: 'id', value: id })
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_VOLUNTEER", response);
  },
  async createVolunteer({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("ADD_VOLUNTEER", response);
      notiMessage.displayNotiMsg('Saved Success')
      return true
    }

    return false;
  },
  async updateVolunteer(context, payload) {
    console.log(payload)
    const response = await Request.post_data(updateUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg('Updated Success')
      return true
    }
    return false
  },
  async deleteVolunteer({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_VOLUNTEER", payload);
      notiMessage.displayNotiMsg('Deleted Success')
      return true
    }
    return false;
  },
  async recoverVolunteer({ commit }, payload) {
    const response = await Request.post_data(recoverUrl, payload)
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_VOLUNTEER", payload);
      notiMessage.displayNotiMsg('Recover Volunteer Data Success')
      return true
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
