import apiClient from "./Http";
import Loading from "./Loading";
import router from "@/router";

const loaderState = new Loading();

function setCompleteUrl(url, paramObj) {
  //check URL has params
  //loop params object keys and value and then added to api call URL
  if (paramObj) {
    Object.keys(paramObj).forEach((key) => {
      url = url + `&${key}=${paramObj[key]}`;
    });
  }
  return url;
}

export default {
  async post_data(url, payload, params) {
    let completeUrl = setCompleteUrl(url, params);
    //set loading state true
    loaderState.startLoading();
    return apiClient
      .post(completeUrl, payload)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          router.push("/login");
        }
      })
      .finally(() => {
        //set loading state false
        loaderState.endLoading();
      });
  },
  get_data(url, params) {
    let completeUrl = setCompleteUrl(url, params);
    //set loading state true
    loaderState.startLoading();
    return apiClient
      .get(completeUrl)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          router.push("/login");
        }
      })
      .finally(() => {
        //set loading state false
        loaderState.endLoading();
      });
  },
};
