import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage("success");

const errorMessage = new NotiMessage("error");

//clear all state
const getDefaultState = () => {
  return {
    isLoggedIn: false,
    admin: {},
  };
};

//URL for admin module
const url = "your";
const loginUrl = "/login";
const changePasswordUrl = "your/changePassword";

const state = {
  dialog: false,
  isLoggedIn: false,
  admin: {},
};
const getters = {};
const mutations = {
  CHANGE_DIALOG_STATE(state) {
    state.dialog = !state.dialog;
  },
  SET_ADMIN(state, admin) {
    state.admin = admin;
  },
  CHANGE_LOGGED_IN_STATUS(state) {
    state.isLoggedIn = !state.isLoggedIn;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  setDialog({ commit }) {
    commit("CHANGE_DIALOG_STATE");
  },
  async getAdmin({ commit }) {
    const response = await Request.get_data(url);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_ADMIN", response.data.data.user);
  },
  async login({ commit }, payload) {
    const response = await Request.post_data(loginUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      localStorage.setItem("MDR_DASHBOARD_PA_TOKEN", response.data.data.token);
      notiMessage.displayNotiMsg("Welcome Back!");
      commit("CHANGE_LOGGED_IN_STATUS");
      return true;
    }
    errorMessage.displayNotiMsg("login information must be valid");
    return false;
  },
  async logout({ commit }) {
    localStorage.clear();
    commit("resetState");
    return true;
  },
  async changePassword(context, payload) {
    const response = await Request.post_data(changePasswordUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg("Chaged Password Success!");
      return true;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
