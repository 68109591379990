import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";
import apiClient from "../../services/Http";

const notiMessage = new NotiMessage("success");
//URL List
const eachDataUrl = "activity/he/getEach?";
const allDataUrl = "activity/he/get?";
const storeUrl = "activity/he/store";
const updateUrl = "activity/he/update";
const deleteUrl = "activity/he/delete";

const getDefaultState = () => {
  return {
    heEachData: {},
  };
};

const state = {
  heData: [],
  heEachData: {},
  symptomsOptions: [],
};
const getters = {};
const mutations = {
  SET_HE_DATA(state, payload) {
    state.heData = payload.data.data;
  },
  SET_SYMPTOMS(state, payload) {
    state.symptomsOptions = payload.data.sympton;
  },
  SET_HE_EACH(state, payload) {
    state.heEachData = payload.data.data;
  },
  ADD_HE_DATA(state, payload) {
    state.heData.push(payload.data.data);
  },
  REMOVE_HE_DATA(state, payload) {
    const heData = state.heData.filter((item) => item.id != payload.id);
    state.heData = [...heData];
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

};

const actions = {
  async getAll({ commit }, payload) {
    const response = await Request.get_data(allDataUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_HE_DATA", response);
  },
  async getSymptoms({ commit }) {
    const response = await Request.get_data('basic_data/get');
    if (response){
      commit("SET_SYMPTOMS", response);
    }
      
  },
  async getEach({ commit }, id) {
    commit("resetState");
    const response = await Request.get_data(eachDataUrl, {
      column: "id",
      value: id,
    });
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_HE_EACH", response);
  },
  async create({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("ADD_HE_DATA", response);
      notiMessage.displayNotiMsg("Saved Success");
      return true;
    }
    return false;
  },
  async update(context, payload) {
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      notiMessage.displayNotiMsg("Updated Success");
      return true;
    }
    return false;
  },
  async delete({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    const responseChecker = new ResponseChecker(response);
    responseChecker.checkResponse();
    if (responseChecker.isResponseStatusSuccess()) {
      commit("REMOVE_HE_DATA", payload);
      notiMessage.displayNotiMsg("Deleted Success");
      return true;
    }

    return false;
  },

  async exportHE(context, payload){

    let url = `https://api.mdr.tbtheunion.com/api/activity/he/export?`;
        if (payload) {
            Object.keys(payload).forEach((key) => {
                url += `&${key}=${payload[key]}`;
            });
        }
        await apiClient
            .get(
                url, {
                    responseType: "blob",
                }
            )
            .then(() => {
                window.location = url;
            });
  },

  

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
