import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import {
    typeOfPatientOpt,
    typeOfTBOpt,
    cultureResultOpt,
    cxrResultOpt,
    comorbidDieaseOpt,
    hivResultOpt,
    artRxOpt,
    cptRxOpt,
} from "./../../services/options.js";

//URL List
const towlshipsUrl = 'township/get?';
const outsideTowlshipsUrl = 'outside_township/get?';

const state = {
    hivStatusOpt: [
        { id: 1, text: "No" },
        { id: 2, text: "Yes" },
        { id: null, text: " " },
    ],
    mdrTbRegInitialOpt: [
        { id: 1, text: "M" },
        { id: null, text: " " },
    ],
    cptRxOpt: cptRxOpt,
    transferInOpt: [
        { id: 1, text: "No" },
        { id: 2, text: "Yes" },
        { id: null, text: " " },
    ],
    artRxOpt: artRxOpt,
    outTwnOpt: [
        { id: 1, text: "No" },
        { id: 2, text: "Yes" },

        { id: null, text: " " },
    ],
    monopoly: [
        { id: "1", text: "No" },
        { id: "2", text: "Yes" },
        { id: "3", text: "SG" },
    ],
    typeOfTFIn: [
        { id: 1, text: "Within Project" },
        { id: 2, text: "Outside" },
        { id: null, text: " " },
    ],
    lpaResultOpt: [
        { id: 1, text: "H Resistant" },
        { id: 3, text: "R Resistant" },
        { id: 6, text: "E Resistant" },
        { id: 8, text: "S Resistant" },
        { id: 10, text: "Amk Resistant" },
        { id: 12, text: "Km Resistant" },
        { id: 14, text: "Cm Resistant" },
        { id: 16, text: "FQ Resistant" },
        { id: 2, text: "H Susceptible" },
        { id: 5, text: "R Susceptible" },
        { id: 7, text: "E Susceptible" },
        { id: 9, text: "S Susceptible" },
        { id: 11, text: "Amk Susceptible" },
        { id: 13, text: "Km Susceptible" },
        { id: 15, text: "Cm Susceptible" },
        { id: 17, text: "FQ Susceptible" },
        { id: 18, text: "Contaminated" },
        { id: null, text: " " },
    ],
    lpaResistantOpt: [
        { id: 1, text: "H Resistant" },
        { id: 3, text: "R Resistant" },
        { id: 6, text: "E Resistant" },
        { id: 8, text: "S Resistant" },
        { id: 10, text: "Amk Resistant" },
        { id: 12, text: "Km Resistant" },
        { id: 14, text: "Cm Resistant" },
        { id: 16, text: "FQ Resistant" },
    ],
    lpaSusceptibleOpt: [
        { id: 2, text: "H Susceptible" },
        { id: 5, text: "R Susceptible" },
        { id: 7, text: "E Susceptible" },
        { id: 9, text: "S Susceptible" },
        { id: 11, text: "Amk Susceptible" },
        { id: 13, text: "Km Susceptible" },
        { id: 15, text: "Cm Susceptible" },
        { id: 17, text: "FQ Susceptible" },
        { id: 18, text: "Contaminated" },
        { id: null, text: " " },
    ],
    comorbidDieaseOpt: comorbidDieaseOpt,
    mdrTbRegs: [
        { id: 1, text: "Conventional" },
        { id: 2, text: "Conventional with PAS" },
        { id: 3, text: "Individualized regimen" },
        { id: 4, text: "STR" },
        { id: 5, text: "Pre-XDR" },
        { id: 6, text: "XDR" },
        { id: 7, text: "Poly-drug resistant" },
        { id: 8, text: "OLTR" },
        { id: 9, text: "OSSTR" },
        { id: 10, text: "BPal" },
        { id: 11, text: "BPalM" },
        { id: 12, text: "LTR" },
        { id: 13, text: "Mono-Drug Resistance" },
        { id: 14, text: "SSTR" },
        { id: 15, text: "Individualized MDR" },
        { id: 16, text: "Individualized Pre-XDR" },
        { id: null, text: " " },
      ],
    typeOfPatientOpt: typeOfPatientOpt,
    subTypeRxOpt: [
        { id: 1, text: '1.1 SSTF (LTFU/Failure/Relapse)' },
        { id: 2, text: '1.2 LTR (LTFU/Failure/Relapse)' },
        { id: 3, text: '2.1 OSSTR (LTFU/Failure/Relapse)' },
        { id: 4, text: '2.2 OLTR (LTFU/Failure/Relapse)' },
    ],
    subTypeOtherOpt: [
        { id: 1, text: 'Unknown Regimen/outcome of previously treated TB' },
        { id: 2, text: 'Patient who does not fit in registration group 1 to 6' }
    ],
    typeOfTBOpt: typeOfTBOpt,
    townships: [],
    outside_townships: [],
    hivResultOpt: hivResultOpt,

    cultureResultOpt: cultureResultOpt,
    cxrResultOpt: cxrResultOpt,
};
const getters = {};

const mutations = {
    SET_TOWNSHIPS(state, payload) {
        state.townships = payload.data.data;
    },
    SET_OUTSIDE_TOWNSHIPS(state, payload) {
        state.outside_townships = payload.data.data;
    },
};

const actions = {
    async getTownships({ commit }) {
        const response = await Request.get_data(towlshipsUrl)
        const responseChecker = new ResponseChecker(response)
        responseChecker.checkResponse()
        if (responseChecker.isResponseStatusSuccess()) commit("SET_TOWNSHIPS", response);
    },
    async getAllTownships({ commit }) {
        const response = await Request.get_data('township/get-all');
        if(response.status){
          commit("SET_TOWNSHIPS", response);
        }
      },
    async getOutsideTownships({ commit }) {
        const response = await Request.get_data(outsideTowlshipsUrl)
        const responseChecker = new ResponseChecker(response)
        responseChecker.checkResponse()
        if (responseChecker.isResponseStatusSuccess()) commit("SET_OUTSIDE_TOWNSHIPS", response);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};