import ValidateChecker from '../services/ValidateChecker'

export const ValidationMixin = {
  data: () => ({
    toastErrors: []
  }),
  methods: {
    //*use this function to bind in vuetify textfield
    handleFieldErrors(field) {
      let errors = [];
      if (!field.$dirty) return errors;

      if (field.$error) {

        const validateChecker = new ValidateChecker(field);

        if (validateChecker.checkRequired())
          errors.push("this field is required");

        if (validateChecker.checkEmail())
          errors.push("email format invalid");

        if (validateChecker.checkSameAs())
          errors.push("Password must be identical.");

        if (validateChecker.checkMinLength())
          errors.push(`Digit numbers should be ${field.$params.minLength.min}`);

        if (validateChecker.checkMaxLength())
          errors.push(`Digit numbers should be ${field.$params.minLength.max}`);

        if (validateChecker.checkBetween())
          errors.push(`Age should be between ${field.$params.between.min} and ${field.$params.between.max}`);

      }

      return errors;
    },
    //*use this function for display toast errors
    checkToastValidationMsg(validation) {
      //fist pass the vuelidate $v object
      //and then loop through using object entries
      for (const fieldArray of Object.entries(validation)) {
        //javascript object entries return  array
        //this array includes two pair [key,value] of value
        let field = fieldArray[1]
        let fieldName = fieldArray[0]
        //used vuelidate $invalid property to check textfield is valid
        if (field.$invalid) {

          const validateChecker = new ValidateChecker(field);

          if (validateChecker.checkRequired()) this.toastErrors.push(`${fieldName} is required`);

          if (validateChecker.checkMaxLength()) this.toastErrors.push(`${fieldName} Digit numbers should be ${field.$params.maxLength.max}`);

          if (validateChecker.checkMinLength()) this.toastErrors.push(`${fieldName} min digit is ${field.$params.minLength.min}`);

          if (validateChecker.checkBetween())
            this.toastErrors.push(`${fieldName} should be between ${field.$params.between.min} and ${field.$params.between.max}`);
        }
      }
    },
    clearToastErrors() {
      this.toastErrors = []
    }
  },
};
