import Request from "../../services/Request";
import ResponseChecker from "../../services/ResponseChecker";
import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage('success');

//URL List
const eachDataUrl = "refer_patient/getEach?";
const allDataUrl = "refer_patient/get?";
const storeUrl = "refer_patient/store";
const updateUrl = "refer_patient/update";
const deleteUrl = "refer_patient/delete";

const state = {
  referPresumptiveData: [],
  referPresumptiveDataEach: {},
};
const getters = {
  referPresumptiveDataEach(state) {
    state.referPresumptiveDataEach.sex =
      state.referPresumptiveDataEach.sex + "";
    return state.referPresumptiveDataEach;
  },
};
const mutations = {
  SET_REFER_PRESUMPTIVE_DATA(state, payload) {
    state.referPresumptiveData = payload.data.data;
  },
  APPEND_REFER_PRESUMPTIVE_DATA(state, payload) {
    state.referPresumptiveData.push(payload.data.data);
  },
  SET_REFER_PRESUMPTIVE_DATA_EACH(state, payload) {
    state.referPresumptiveDataEach = payload.data.data;
  },
  REMOVIE_REFER_PRESUMPTIVE_DATA(state, payload) {
    state.referPresumptiveData = state.referPresumptiveData.filter((val) => {
      return val.id != payload;
    });
  },
};

const actions = {
  async save({ commit }, payload) {
    const response = await Request.post_data(storeUrl, payload);
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("APPEND_REFER_PRESUMPTIVE_DATA", response);
      notiMessage.displayNotiMsg('Saved Success')
      return true
    }
    return false;
  },
  async edit({ commit }, payload) {
    const response = await Request.post_data(updateUrl, payload);
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess()) {
      commit("APPEND_REFER_PRESUMPTIVE_DATA", response);
      notiMessage.displayNotiMsg('Updated Success')
      return true
    }

    return false;
  },
  async deleteReferPatient({ commit }, payload) {
    const response = await Request.post_data(deleteUrl, payload);
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseSuccess()) {
      commit("REMOVIE_REFER_PRESUMPTIVE_DATA", payload.id);
      notiMessage.displayNotiMsg('Deleted Success')
      return true
    }
    return false
  },
  async getAll({ commit }) {
    const response = await Request.get_data(allDataUrl);
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess())
      commit("SET_REFER_PRESUMPTIVE_DATA", response);
  },
  getEach({ commit }, payload) {
    const response = Request.get_data(eachDataUrl, { column: "id", value: payload });
    const responseChecker = new ResponseChecker(response)
    responseChecker.checkResponse()
    if (responseChecker.isResponseStatusSuccess)
      commit("SET_REFER_PRESUMPTIVE_DATA_EACH", response);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
