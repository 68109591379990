import store from "../store";

export default class NotiMessage {
    _notification = {};

    constructor(type) {
        this._notification.type = type;
    }
    displayNotiMsg(message) {
        if (message) {
            this._notification.message = message
            store.dispatch("Notification/add", this._notification);
        }
    }

}


