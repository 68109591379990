import axios from "axios";

import NotiMessage from "../../services/NotiMessage";

const notiMessage = new NotiMessage("success");

const getDefaultState = () => {
  return {
    feedbacks: [],
  };
};

const state = {
  feedbacks: [],
  feedbackCount: {},
};
const getters = {};
const mutations = {
  SET_DATA(state, payload) {
    state.feedbacks = payload.data.data;
  },
  SET_COUNT(state, payload) {
    state.feedbackCount = payload.data.data;
  },
  ADD_DATA(state, payload) {
    state.feedbacks.push(payload.data.data);
  },
  REMOVE_DATA(state, id) {
    const feedbacks = state.feedbacks.filter((item) => item.id != id);
    state.feedbacks = [...feedbacks];
  },
  CHANGE_STATUS_DONE(state, payload) {
    state.feedbacks = state.feedbacks.map((item) => {
      if (item.id === payload.id) return { ...item, done: payload.done };
      return item;
    });
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  getAll({ commit }, payload) {
    axios
      .get(
        `https://api.mdr.tbtheunion.com/api/get-comments?&item_id=${payload.item_id}&item_type=${payload.item_type}`
      )
      .then((response) => {
        console.log(response);
        commit("SET_DATA", response);
      });
  },
  getCount({ commit }, payload) {
    const { author_type, done, township_id } = payload;
    axios
      .get(
        `https://api.mdr.tbtheunion.com/api/get-comments-count?author_type=${author_type}&done=${done}&township_id=${township_id}`
      )
      .then((response) => {
        console.log(response);
        commit("SET_COUNT", response);
      });
  },

  create({ commit }, payload) {
    axios
      .post(`https://api.mdr.tbtheunion.com/api/save-comments`, payload)
      .then((response) => {
        commit("ADD_DATA", response);
        notiMessage.displayNotiMsg("Saved Success");
      });
  },
  delete({ commit }, id) {
    axios
      .post(`https://api.mdr.tbtheunion.com/api/delete-comments`, { id })
      .then(() => {
        commit("REMOVE_DATA", id);
        notiMessage.displayNotiMsg("Deleted Success");
      });
  },
  changeStatusDone({ commit }, payload) {
    axios
      .post(`https://api.mdr.tbtheunion.com/api/done-comments`, {
        id: payload.id,
      })
      .then((response) => {
        console.log(response);
        commit("CHANGE_STATUS_DONE", payload);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
