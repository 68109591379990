export default [
    {
        path: "/dots",
        name: "dots",
        component: () => import("@/views/dots/DOTS.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/dots-detail/:id",
        name: "dots-detail",
        component: () => import("@/views/dots/DOTSDetail.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/dots-patient-export",
        name: "dots-patient-export",
        component: () => import("@/views/dots/DOTSExport.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/dots-activity/:id",
        name: "dots-activity",
        component: () => import("@/views/dots/DOTSActivity.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/volunteer-activity/:id",
        name: "volunteer-activity",
        component: () => import("@/views/dots/VolunteerActivity.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/app-user-report/:id",
        name: "app-user-report",
        component: () => import("@/views/dots/AppUserReport.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/no-app-user-report/:id",
        name: "no-app-user-report",
        component: () => import("@/views/dots/NoAppUserReport.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/dots-create/:patient_id",
        name: "create-dots-patient",
        component: () => import("@/views/dots/CreateDOTSPatient.vue"),
        meta: { forAuth: true },
    },
    {
        path: "/new-dots",
        name: "new-dots",
        component: () => import("@/views/dots/create/index.vue"),
        meta: { forAuth: true },
    },

]