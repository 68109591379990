export function getCustomFields(valueObj) {
  let newObj = {
    Date: valueObj.date,
    " MDRTB Code": valueObj.mdrtb_code,
    " MDRTB Year": valueObj.mdrtb_year,
    Code: valueObj.mdrtb_code,
    Year: valueObj.mdrtb_year,
    Type: valueObj.type == 2 ? "By Phone" : "By Person",
    "DOT Type":
      valueObj.dots_type == 2 ? "By DOT card" : "Though DOT application",
    Remark: valueObj.remark,
  };

  return newObj;
}
