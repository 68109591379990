<template>
  <!-- hide on screens which smaller than large -->
  <div class="d-none d-lg-block">
    <v-toolbar dense flat class="top-nav white--text">
      <v-toolbar-title
        v-if="admin.township"
        class="text-weight-400 dashboard--title"
      >
        Dashboard | {{ admin.township.name }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-title class="subtitle-2 mr-3">
        <span>
          <v-btn color="white" icon>
            <v-icon>mdi-shield-account-outline</v-icon>
          </v-btn>
          {{ admin.name }}
        </span>
      </v-toolbar-title>

      <v-toolbar-title @click="logout" class="subtitle-2">
        <v-btn color="white" icon> <v-icon>mdi-logout</v-icon> </v-btn>
        LOGOUT
      </v-toolbar-title>
    </v-toolbar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("Admin", ["admin"]),
    ...mapState("Loading", ["loading"]),
  },
  methods: {
    showChangePassword() {
      this.$store.dispatch("Admin/setDialog");
    },
    logout() {
      this.$store.dispatch("Admin/logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style scoped>
.top-nav {
  box-shadow: var(--primary-box-shadow) !important;
  background-image: linear-gradient(
    to top,
    #aa00ff,
    #ad10fd,
    #af1bfc,
    #b223fa,
    #b42af9
  );
}
.v-toolbar__title {
  cursor: pointer;
}
.dashboard--title {
  font-weight: 300 !important;
  letter-spacing: 2px;
  font-size: 17px;
}
</style>
