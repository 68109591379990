import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuelidate from "vuelidate";
import VueSignaturePad from "vue-signature-pad";
import vuetify from "./plugins/vuetify";
import VueToast from "./plugins/VueToast";
import Loading from "./plugins/Loading";
import excel from 'vue-excel-export'

Vue.config.productionTip = false;

Vue.use(vuelidate);
Vue.use(VueSignaturePad);
Vue.use(excel)

new Vue({
  router,
  store,
  vuetify,
  Loading,
  VueToast,
  render: (h) => h(App),
}).$mount("#app");
